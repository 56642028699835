import DragDrop, { getSectionIndex } from '../dragdrop/DragDrop'
import React, { Component, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { EmptyFunc, pathServer, WIDTH_VIEW_MOBILE } from '../../../common/Constants'
import DPDroppable from '../dragdrop/Droppable'
import DPDraggable from '../dragdrop/Draggable'
import { TaskHeader, TaskUpdated } from './Task'
import { cloneDeep, findIndex } from 'lodash'
import DPButton from '../../../common/genericComponents/DPButton'
import { ReactSVG } from 'react-svg'
import { TASK_TYPE, TYPES_TASKS } from '../../Constants'
import { priorities } from './TasksListUpdated'

const minHeight = 100
const ListTaskHorizontal = ({sections,reorder,move,minWidthTask,typeSelected,editTask,deleteTask,minWidthContainer,changeActiveTask,changeInactiveTaskSubmit, isOpen, openBoard, tasks, reorderTaskParent, moveSubtasks, startSubtask, changeToInactiveTask, editSubtask,lineHeight, height}) => {
  let itemsCount = 0
  sections.forEach(section => {
    itemsCount = itemsCount + section.items.length
  })

  const headerRef = useRef(null);
  const bodyRef = useRef(null);
  const bodyUpdateRef = useRef(null);

  const handleScroll = (e) => {
    const scrollLeft = e.target.scrollLeft;
    // Sincroniza el scroll de todos los contenedores
    if (headerRef.current) headerRef.current.scrollLeft = scrollLeft;
    if (bodyRef.current) bodyRef.current.scrollLeft = scrollLeft;
    if (bodyUpdateRef.current) bodyUpdateRef.current.scrollLeft = scrollLeft;
  };

  return(
    <div id='dashboardContainer' className='dashboard-container'>
      <div id='taskBoxHeader' className='task-box-header' style={{minWidth:minWidthContainer}} ref={headerRef} onScroll={handleScroll}>
        <div id='taskSectionHeader' className='task-section-header'>
          {
            sections.map((section,index) => {
              const {id,items,col,image,color,displayValue} = section;
              return(
                <SectionHeader key={`section-header-${id}`}
                               className={col}
                               minWidthTask={minWidthTask}
                               itemsQuantity={items.length}
                               displayValue={displayValue}
                               image={image}
                               typeSelected={typeSelected}
                               backgroundColor={color}
                               id={id}
                               toggleDroppable={() => {}}
                               show={true}
                />
              )
            })
          }
        </div>
      </div>
      {itemsCount && <SectionBody id={'bodyTask'}
                   classNameWrapper={'container-body-task'}
                   classNameSubWrapper={'task-section-body'}
                   sections={sections}
                   reorder={reorder}
                   move={move}
                   minWidthTask={minWidthTask}
                   typeSelected={typeSelected}
                   editTask={editTask}
                   deleteTask={deleteTask}
                   minWidthContainer={minWidthContainer}
                   changeActiveTask={changeActiveTask}
                   changeInactiveTaskSubmit={changeInactiveTaskSubmit}
                   lineHeight={lineHeight}
                   height={height}
                   ref={bodyRef} // Ref para sincronizar
                   onScroll={handleScroll}
      />}
      <SectionBodyUpdate id={'bodyTaskUpdate'}
                         classNameWrapper={'container-body-task'}
                         classNameSubWrapper={itemsCount > 0 ? 'task-section-body' : 'only-task-section-body'}
                         sections={tasks}
                         reorder={reorderTaskParent}
                         move={moveSubtasks}
                         minWidthTask={minWidthTask}
                         typeSelected={typeSelected}
                         editTask={editTask}
                         editSubtask={editSubtask}
                         deleteTask={deleteTask}
                         minWidthContainer={minWidthContainer}
                         changeActiveTask={changeActiveTask}
                         changeInactiveTaskSubmit={changeInactiveTaskSubmit}
                         openIs={isOpen}
                         openBoard={openBoard}
                         startSubtask={startSubtask}
                         changeToInactiveTask={changeToInactiveTask}
                         ref={bodyUpdateRef}
                         onScroll={handleScroll}
      />
    </div>
  )
};

const ListTaskVertical = ({sections,reorder,move,minWidthTask,typeSelected,editTask,deleteTask,minWidthContainer,changeActiveTask,changeInactiveTaskSubmit, showSections, sectionIds, toggleSectionDroppable}) => {
  const sectionsLength = sections.length;
  return(
    <div className={'list-task-vertical'}>
      <DragDrop sections={sections} reorder={reorder} move={move}>
        {
          sections.map((section,sectionIndex) => {
            const {id,items,col,image,color,displayValue} = section;
            return(
              <div key={`listTaskVertical${sectionIndex}`} className={'col100'}>
                <div className='task-box-header-vertical' style={{minWidth:minWidthContainer}}>
                  <div className='task-section-header-vertical'>
                    <SectionHeader className={col}
                                   minWidthTask={minWidthTask}
                                   itemsQuantity={items.length}
                                   displayValue={displayValue}
                                   image={image}
                                   typeSelected={typeSelected}
                                   backgroundColor={color}
                                   id={id}
                                   toggleDroppable={toggleSectionDroppable}
                                   show={showSections[sectionIds.indexOf(id)]}
                    />
                    <DroppableContainer id={id}
                                        classNameWrapper={col}
                                        typeSelected={typeSelected}
                                        items={items}
                                        sectionIndex={sectionIndex}
                                        editTask={editTask}
                                        deleteTask={deleteTask}
                                        minWidthTask={minWidthTask}
                                        changeActiveTask={changeActiveTask}
                                        changeInactiveTaskSubmit={changeInactiveTaskSubmit}
                                        show={showSections[sectionIds.indexOf(id)]}
                    />
                  </div>
                </div>
              </div>
            )
          })
        }
      </DragDrop>
    </div>
  )
};

const SectionHeader = ({minWidthTask,displayValue,itemsQuantity,typeSelected,image,className,backgroundColor,id,toggleDroppable, show}) => {
  const isMobileView = window.screen.width < WIDTH_VIEW_MOBILE
  return(
    <div className={`container-section-task ${className}`} style={{ minWidth: minWidthTask}}>
      <div className="card" onClick={() => toggleDroppable(id)} style={isMobileView ? { cursor: 'Pointer' } : {}}>
        {
          isMobileView ?
            show ?
              <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_more.svg`}
                        className="icon-arrow-content"
                        beforeInjection={svg => svg.classList.add('icon-arrow-expand')}/> :
              <ReactSVG src={`${pathServer.PATH_IMG}icon/ic_expand_less.svg`}
                        className="icon-arrow-content"
                        beforeInjection={svg => svg.classList.add('icon-arrow-expand')}/>
            : null
        }
        <div className="card-name" style={isMobileView ? { paddingLeft: 10 } : {}}>
          <h4>{displayValue}</h4>
        </div>
        <div className="card-task" style={isMobileView ? { backgroundColor: backgroundColor } : { display: 'none' }}>
          <div className="card-number">
            <h1>{itemsQuantity}</h1>
          </div>
          {
            typeSelected === TASK_TYPE.NORMAL ?
              <div className="card-images">
                <img src={image}/>
              </div> : null
          }
        </div>
      </div>
    </div>


  )
};


const SectionBody = forwardRef(({
                       id,
                       classNameWrapper,
                       classNameSubWrapper,
                       sections,
                       reorder,
                       move,
                       minWidthTask,
                       typeSelected,
                       editTask,
                       deleteTask,
                       minWidthContainer,
                       changeActiveTask,
                       changeInactiveTaskSubmit,
                       lineHeight,
                       height,
                       onScroll
                     }, ref) => {
  const containerRef = useRef(null);
  const [heightLine, setHeightLine] = useState(0)
  //useImperativeHandle(ref, () => containerRef.current);
  useEffect(() => {
    if (containerRef.current) {
      setHeightLine(containerRef.current.offsetHeight);
    }
  }, []);
  return (
    <div id={id} className={classNameWrapper} style={{minWidth:minWidthContainer}} ref={typeSelected === TASK_TYPE.REPAIR ? containerRef : null} onScroll={onScroll}>
      <div id={'taskSectionBody'} className={classNameSubWrapper} ref={typeSelected === TASK_TYPE.NORMAL ? containerRef : null}>
        <DragDrop sections={sections} reorder={reorder} move={move}>
          {
            sections.map((section,sectionIndex) => {
              const {id,items,col} = section;
              return(
                <DroppableContainer key={id}
                                    sectionIndex={sectionIndex}
                                    id={id}
                                    classNameWrapper={col}
                                    minWidthTask={minWidthTask}
                                    items={items}
                                    editTask={editTask}
                                    typeSelected={typeSelected}
                                    deleteTask={deleteTask}
                                    changeActiveTask={changeActiveTask}
                                    changeInactiveTaskSubmit={changeInactiveTaskSubmit}
                                    show={true}
                />
              )
            })
          }
        </DragDrop>
        <div className={'line-1'} style={{height: heightLine}}></div>
        <div className={'line-2'} style={{height: heightLine}}></div>
      </div>
    </div>
  )
});


const SectionBodyUpdate = ({id,classNameWrapper,classNameSubWrapper,sections,reorder,move,minWidthTask,typeSelected,editTask,deleteTask,minWidthContainer,changeActiveTask,changeInactiveTaskSubmit, openIs, openBoard,startSubtask,changeToInactiveTask, editSubtask,ref, onScroll}) => {

  const [sourceId, setSourceId] = useState("");
  const [destinationId, setDestinationId] = useState("");
  const [isDropDisable, setIsDropDisable] = useState(false)

  const reorderTasksSubtasks = (list, startIndex, endIndex) => {
    const result = cloneDeep(list)
    //[...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  /**
   * Moves an item from one list to another list.
   */
  const moveTasksSubtasks = (sections, droppableSource, droppableDestination) => {
    const droppableSourceId = droppableSource.droppableId.split('-').pop();
    const droppableDestinationId = droppableDestination.droppableId.split('-').pop()
    const sectionsToUpdate = [...sections];
    const sourceIndex = findIndex(sectionsToUpdate, function (section) {return section.id === droppableSourceId});
    const destinationIndex = findIndex(sectionsToUpdate, function (section) {return section.id === droppableDestinationId});
    const sourceClone = [...sectionsToUpdate[sourceIndex].items];
    const destClone = [...sectionsToUpdate[destinationIndex].items];
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    removed.status = droppableDestinationId;
    destClone.splice(droppableDestination.index, 0, removed);
    sectionsToUpdate[sourceIndex].items = sourceClone;
    sectionsToUpdate[destinationIndex].items = destClone;

    return sectionsToUpdate;
  };

  const compareDragContainers = (str1, str2) => {
    // Extract the middle codes by splitting the strings on "-"
    const code1 = str1.split("-")[1];
    const code2 = str2.split("-")[1];

    // Compare the extracted codes
    return code1 === code2;
  }

  const getItemsToUpdate = (sections, droppableSource, droppableDestination) => {
    const droppableSourceId = droppableSource.droppableId.split('-').pop();
    const droppableDestinationId = droppableDestination.droppableId.split('-').pop()
    const sourceIndex = findIndex(sections, function (section) {return section.id === droppableSourceId});
    const destinationIndex = findIndex(sections, function (section) {return section.id === droppableDestinationId});
    const items = [];
    sections[sourceIndex].items.forEach((item,index) => {
      items.push({id:item.id, order: (index + 1)})
    });
    sections[destinationIndex].items.forEach((item,index) => {
      const itemToInsert = {id:item.id, order: (index + 1)};
      if(index === droppableDestination.index)
        itemToInsert.status = item.status;

      items.push(itemToInsert);
    });
    return items
  };

  const getSectionIndex = (sections, id, type) => {
    if (type === TYPES_TASKS.SUBTASK){
      const index = findIndex(sections, function (section) {
        return section.id === id.split('-').pop();
      });
      return index;
    }else if(type === TYPES_TASKS.TASK){
      const index = findIndex(sections, function (section) {
        return section.id === id;
      });
      return index;
    }
  };

  const onDragUpdate = result => {
    const {source, destination} = result

    setSourceId(source.droppableId)
    setDestinationId(destination.droppableId)
    setIsDropDisable(!compareDragContainers(source.droppableId,destination.droppableId))

  }


  const onDragEnd = (result) => {
    const { source, destination, type  } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    let itemsToUpdate = [];

    if (source.droppableId === destination.droppableId) {

      if(type === TYPES_TASKS.SUBTASK){
        const sectionsToReorder = cloneDeep(sections);
        const taskElement = sectionsToReorder[0].items.find(task => task.id === source.droppableId.split("-")[1])
        const sectionIndex = getSectionIndex(taskElement.sections,source.droppableId, type);
        const items = reorderTasksSubtasks(
          taskElement.sections[sectionIndex].items,
          source.index,
          destination.index
        );
        taskElement.sections[sectionIndex].items = items;
        items.forEach((item,index) => {
          itemsToUpdate.push({id: item.id, order: (index + 1)});
        });
        reorder(sectionsToReorder,itemsToUpdate);
      }else if(type === TYPES_TASKS.TASK){
        const sectionsToReorder = cloneDeep(sections);
        const sectionIndex = getSectionIndex(sectionsToReorder, source.droppableId, type)
        const items = reorderTasksSubtasks(
          sectionsToReorder[sectionIndex].items,
          source.index,
          destination.index
        );
        sectionsToReorder[sectionIndex].items = items;
        items.forEach((item, index) => {
          itemsToUpdate.push({id: item.id, order: (index + 1)});
        })
        reorder(sectionsToReorder,itemsToUpdate)
      }
    } else {
      if(type === TYPES_TASKS.SUBTASK){
        const sectionsToReorder = cloneDeep(sections);
        const taskElement = sectionsToReorder[0].items.find(task => task.id === source.droppableId.split("-")[1])
        const result = moveTasksSubtasks(
          taskElement.sections,
          source,
          destination
        );
        itemsToUpdate = getItemsToUpdate(result,source,destination);
        move(result,itemsToUpdate);
      }
    }
  }

  return(
    <div ref={ref} onScroll={onScroll}>
      <div id={'ContainerTasks'} className={classNameWrapper} style={{minWidth: minWidthContainer}}>
        <div id={'taskSectionBodyUpdate'} className={classNameSubWrapper}>
          <DragDrop sections={sections} reorder={reorder} move={EmptyFunc} onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
            {
              sections.map((item, itemIndex) => {
                const { items, title, col,id } = item
                return (
                  <DroppableContainerUpdate key={id}
                                            sectionIndex={itemIndex}
                                            id={id}
                                            classNameWrapper={col}
                                            minWidthTask={minWidthTask}
                                            items={items}
                                            editTask={editTask}
                                            editSubtask={editSubtask}
                                            typeSelected={typeSelected}
                                            deleteTask={deleteTask}
                                            changeActiveTask={changeActiveTask}
                                            changeInactiveTaskSubmit={changeInactiveTaskSubmit}
                                            show={true}
                                            title={title}
                                            openIs={openIs}
                                            openBoard={openBoard}
                                            move={move}
                                            reorder={reorder}
                                            startSubtask={startSubtask}
                                            changeToInactiveTask={changeToInactiveTask}
                                            isDragDisable={isDropDisable}
                  />
                )
              })
            }
          </DragDrop>
        </div>
      </div>
    </div>
  )
};

const DroppableContainerUpdate = ({id,classNameWrapper,classNameSubWrapper,items,reorder,move,minWidthTask,typeSelected,editTask,deleteTask,minWidthContainer,changeActiveTask,changeInactiveTaskSubmit, openIs,
                                    editSubtask,openBoard,startSubtask,changeToInactiveTask,isDragDisable}) => {
  const styleSubstask = {backgroundColor:'#FFFFFF'}
  const styleDraggableOpen = {marginBottom: 0}
  const styleDraggableClose = {marginBottom: '10px'}
  return(
    <div id={id} className={classNameWrapper} style={{minWidth:minWidthContainer, display:'flow-root'}}>
      <div id={'taskSectionBodySubtask'} className={classNameSubWrapper} style={openBoard ?{display: 'none'} : { width: '100%'}}>

        <DPDroppable droppableId={id} droppableStyle={styleSubstask} type={TYPES_TASKS.TASK}>
          {
            items.map((task,sectionIndex) => {
              const {id,sections,col} = task;
              return(
                <DPDraggable key= {id} itemId={id} index={sectionIndex} draggableStyle={task.showSubtasks ? styleDraggableOpen : styleDraggableClose}>
                  <div className={'subtask-body-container'}>
                    <TaskHeader openIs={openIs}
                                task={task}
                                editTask={editTask}
                                changeToInactiveTask={changeToInactiveTask}
                                progress={task.weighted}/>
                    {
                      /*task.showSubtasks &&*/
                      <DroppableContainerSubtasks id={id}
                                                  classNameWrapper={classNameWrapper}
                                                  classNameSubWrapper={classNameSubWrapper}
                                                  sections={sections}
                                                  reorder={reorder}
                                                  move={move}
                                                  minWidthTask={minWidthTask}
                                                  typeSelected={typeSelected}
                                                  editTask={editTask}
                                                  editSubtask={editSubtask}
                                                  deleteTask={deleteTask}
                                                  minWidthContainer={minWidthContainer}
                                                  changeActiveTask={changeActiveTask}
                                                  changeInactiveTaskSubmit={changeInactiveTaskSubmit}
                                                  startSubtask={startSubtask}
                                                  taskId={id}
                                                  isVisible={task.showSubtasks}
                                                  taskParent={task}
                                                  isDragDisable={isDragDisable}
                      />
                    }
                  </div>
                </DPDraggable>
              )
            })
          }
        </DPDroppable>

      </div>
    </div>
  )
};


const DroppableContainerSubtasks = ({id, classNameWrapper, classNameSubWrapper, sections, reorder,move,minWidthTask,typeSelected,editTask,deleteTask,minWidthContainer,
                                      changeActiveTask,changeInactiveTaskSubmit,startSubtask, taskId, editSubtask, isVisible, taskParent,isDragDisable}) => {

  const containerRef = useRef(null);
  const [height, setHeight] = useState(0)
  useEffect(() => {
    if (containerRef.current) {
      setHeight(containerRef.current.offsetHeight); // Set height when the component is visible
    }
  }, [isVisible, sections]);

  return(
    <div id={id} className={classNameWrapper} ref={containerRef}
         style={{minWidth:minWidthContainer, maxHeight: isVisible ? "100%" : "0",
           opacity: isVisible ? "1" : "0",
           transition: "max-height 0.5s ease-out, opacity 0.5s ease-out",
           overflow: "hidden" // Prevent content from overflowing when collapsed
         }}>
      <div id={'taskSectionBody'} className={'subtaskContainerSection'}>
        {
          sections.map((section, sectionIndex) => {
            const { id, items, col } = section
            return (
              <DroppableContainer key={id}
                                  sectionIndex={sectionIndex}
                                  id={id}
                                  classNameWrapper={col}
                                  minWidthTask={minWidthTask}
                                  items={items}
                                  editTask={editTask}
                                  editSubtask={editSubtask}
                                  typeSelected={typeSelected}
                                  deleteTask={deleteTask}
                                  changeActiveTask={changeActiveTask}
                                  changeInactiveTaskSubmit={changeInactiveTaskSubmit}
                                  show={true}
                                  startSubtask={startSubtask}
                                  taskId={taskId}
                                  taskParent={taskParent}
                                  isDragDisable={isDragDisable}
              />
            )
          })
        }
        <div className={'line-1'} style={{ height: height }}></div>
        <div className={'line-2'} style={{ height: height }}></div>
      </div>
    </div>
  )
};

const DroppableContainer = ({ sectionIndex, id, classNameWrapper, minWidthTask, items, editTask, typeSelected, deleteTask, changeActiveTask, changeInactiveTaskSubmit,
                              show, startSubtask, taskId, editSubtask, taskParent, isDragDisable }) => {

  const sectionId = items.flatMap((item) => item.status).find((id, index, self) => id && self.indexOf(id) === index);
  const isMobileView = window.screen.width < WIDTH_VIEW_MOBILE
  const emptyMobileDroppable = 10
  const minDroppableHeight = isMobileView ? emptyMobileDroppable : minHeight
  const droppableStyle = !!!items.length ? { minHeight: minDroppableHeight, zIndex: 1 } : sectionId === 'todo' ? { marginLeft: '20px'} :  sectionId === 'done'? {marginRight: '20px'} : {}
  const draggableStyle = isMobileView ? { userSelect: 'none', marginBottom: 5}  :
    sectionId === 'todo' ? { userSelect: 'none', marginBottom: 20, paddingRight: '20px'} :
    sectionId === 'done'? {userSelect: 'none', marginBottom: 20, paddingLeft: '20px'} :
    {
    userSelect: 'none',
    marginBottom: 20,
    padding: '0 20px' }

  return (
    <div id={`${taskId ?? "task"}-section${sectionIndex}`} className={`container-section-task ${classNameWrapper}`}
         style={{ minWidth: minWidthTask, paddingTop: 10, paddingBottom: 15 }}>
      <div className="container-list-task-body">
        {(items.length === 0 && taskId && sectionIndex === 0)  &&
          <DPButton id={id}
                    label={'+ New Subtask'}
                    buttonClassName={'newSubtask'}
                    onClick={(evt) => startSubtask(taskParent, evt)}
          />
        }
        <DPDroppable droppableId={ !!taskId ? `newSection-${taskId}-${id}` : id } droppableStyle={droppableStyle} type={TYPES_TASKS.SUBTASK} isDroppableDisable={isDragDisable}>
          {
            show && items.map((task, index) => {
              const indexPriority = findIndex(priorities, (priority) => {
                return priority.id === task.priority
              })
              const styleBorderLeft = priorities[indexPriority].styleBorder

              return (
                    <DPDraggable key={task.id} itemId={task.id} index={index} draggableStyle={draggableStyle}>
                      <TaskUpdated task={task}
                                   styleBorderLeft={{ borderLeft: styleBorderLeft }}
                                   editTask={editTask}
                                   editSubtask={editSubtask}
                                   typeSelected={typeSelected}
                                   deleteTask={deleteTask}
                                   changeActiveTask={changeActiveTask}
                                   changeInactiveTaskSubmit={changeInactiveTaskSubmit}
                                   taskIndex={index}
                                   sectionIndex={sectionIndex}
                                   type={task?.parentTaskId ? TYPES_TASKS.SUBTASK : TYPES_TASKS.TASK}
                                   taskParent={taskParent}
                      />
                    </DPDraggable>
              )
            })
          }
        </DPDroppable>
        {
          (items.length > 0 && taskId && sectionIndex === 0) &&
          <DPButton id={id}
                    label={'+ New Subtask'}
                    buttonClassName={'newSubtask'}
                    onClick={(evt) => startSubtask(taskParent, evt)}
          />
        }
      </div>
    </div>
  )
};

const TaskListDroppable = ({ props, widthPage, height }) => {

  return(
    //widthPage < WIDTH_VIEW_MOBILE ?
      //<ListTaskVertical {...props} /> :
      <ListTaskHorizontal {...props} height={height}/>
  )
}

export default TaskListDroppable;
