import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import RowCost from './RowCost'
import DynamicNumber from 'react-dynamic-number'
import {usdNumberToFormat} from '../../Utils'
import {calcSubTotal} from '../../newComponents/inventory/InventoryCar'
import {WIDTH_VIEW_MOBILE} from '../../../common/Constants'

require('./DPCost.scss')
require('../../../common/styles/tasks.scss')

const defaultCost = {
  description: '',
  part: '',
  labor: '',
  total: '',
};

const DPCost = React.forwardRef(({showTitleHeader, showActionFooter, showActionRow, onChange,
                                   removeTaskRepair, openRecipes, countCost, costList, openTaskRepair,
                                   isCarOrTask,receipts,onDropPhoto,deleteReceipt,onSaveReceipts, costOnBlur,
                                   addNewCost, errors, subTasksCost,  isSubtask = false,
                                   classNameForRowCost = 'body-cost-fields'}, ref) => {
  const [ costs, setCosts ] = useState([]);
  const [ subTotalCost, setSubTotalCost ] = useState(0)
  const [ showRecipe, setShowRecipe ] = useState(false);
  const [ rowActive, setRowActive] = useState(-1);

  const calcSubTotalCost = (list = []) => {
      setSubTotalCost(calcSubTotal(list))
  };

  const handleOpenRecipes = (index, repairId) => {
    if (isCarOrTask === 'C') {
      openRecipes(index, repairId)
    } else {
      openRecipes(index, repairId).then(r => setShowRecipe(true))
    }
  };

  useEffect(() => {
    if (isCarOrTask === 'T') {
      setCosts([...costList])
    }else{
      setCosts([...costList, defaultCost])
    }
  }, [])

  useEffect(() => {
    setCosts([...costList])
    calcSubTotalCost(costList)
  }, [costList])

  const formattedSubTotal = usdNumberToFormat(subTotalCost)
  const formattedSubTasksCost  = usdNumberToFormat(subTasksCost)
  const formattedTotal = usdNumberToFormat(subTotalCost + subTasksCost)
    return (
    <div className='body-cost' ref={ref}>
      {showTitleHeader && (subTasksCost !== undefined) && <span className='section-title'>Repair Cost</span>}
      {!isSubtask &&
        (subTasksCost !== undefined) &&
      <div className='total-section'>
        <div className='total'>
          <h3>Total cost</h3>
          <span className='total-value'>${formattedTotal}</span>
        </div>
        <div className='total'>
          <h3>Task costs</h3>
          <span className='total-value'>${formattedSubTotal}</span>
        </div>
        <div className='total'>
          <h3>Subtasks costs</h3>
          <span className='total-value'>${formattedSubTasksCost}</span>
        </div>
      </div>
      }
      {
        window.innerWidth > WIDTH_VIEW_MOBILE && costs.length > 0 &&
        <div className='body-cost-header'>
          <div className='info-column-tit-desc'>
            <div className='info-title'>
              <label className='title'>{!isSubtask ? 'Other cost description' : 'Cost description'}</label>
            </div>
          </div>
          <div className='info-column'>
            <div className='info-conf'>
              <div className='info-price'>
                <label className='title'>Labor</label>
              </div>
              <div className='info-price'>
                <label className='title'>Part</label>
              </div>
              <div className='info-price'>
                <label className='title'>Total</label>
              </div>
            </div>
          </div>
        </div>
      }
      <div className="scroll-container">
        <div className="cost-container">
        {
          costs.map((cost, index) => {
            return (
              <RowCost rowActive={rowActive}
                       key={cost.id ? cost.id : cost.temporalId}
                       index={index}
                       cost={cost}
                       onChange={onChange}
                       openRecipes={() => handleOpenRecipes(index, cost.id)}
                       showActionRow={showActionRow}
                       removeTaskRepair={() => removeTaskRepair(index, cost.id)}
                       openTaskRepair={() => openTaskRepair(index, cost.id, cost.taskId)}
                       onDropPhoto={onDropPhoto}
                       receipts={cost.receipts}
                       deleteReceipt={deleteReceipt}
                       showRecipe={showRecipe}
                       updateShowRecipe={(value) => {
                           setShowRecipe(value)
                           setRowActive(index)
                       }}
                       updateRowActive={setRowActive}
                       isCarOrTask={isCarOrTask}
                       onClosePreview={onSaveReceipts}
                       showAlert={isCarOrTask === 'C' && !cost.carId }
                       costOnBlur={costOnBlur}
                       error={errors.find( error => error.id === (cost.id ?? cost.temporalId) ) ?? {}}
                       className={classNameForRowCost}
              />
            )
          })
        }
        {
          showActionFooter &&
            <div className='footer-cost'>
              <div className='add-cost'>
                <span onClick={addNewCost}>+ Add repair cost</span>
              </div>
              {
                costs.length > 0 &&
                <>
                  <div className='label-sub-total'>Subtotal</div>
                  <div className='info-price sub-total'>
                    <span className='currencyinput'><i className='fa fa-dollar'/></span>
                    <DynamicNumber key={'sub-total'} maxLength='10' id='total' className='cost-input cost-total' value={formattedSubTotal}
                                 positive={true}  placeholder='200.00' negative={true} thousand={true}
                                 separator={'.'} integer={10} fraction={2}/>
                  </div>
                </>
              }
            </div>
        }
        </div>
      </div>
    </div>
  )
})


DPCost.displayName = 'DPCost';

DPCost.defaultProps = {
    onChange: () => {},
    removeTaskRepair: () => {},
    openRecipes: null,
    costList:[],
    countCost: [1],
    onDropPhoto: () => {},
    deleteReceipt: () =>{},
    isCarOrTask: 'T',
    receipts: [],
    onSaveReceipts: PropTypes.func,
    errors: []
};


DPCost.propTypes = {
    onChange: PropTypes.func,
    removeTaskRepair: PropTypes.func,
    openRecipes: PropTypes.func,
    costList: PropTypes.array,
    countCost: PropTypes.array,
    isCarOrTask: PropTypes.string,
    onDropPhoto: PropTypes.func,
    receipts: PropTypes.array,
    deleteReceipt: PropTypes.func,
    onSaveReceipts: PropTypes.func,
};

export default DPCost;