import React, {Component} from 'react'
import {InputNumber, Popover} from '../util/ComponentUtils'
import {ReactSVG} from 'react-svg'
import {
  carStatus,
  COST_REPAIR_LABOR,
  COST_REPAIR_PART,
  FILTER_TYPE_TASK,
  TASK_STATUS_COMPLETE,
  TASK_STATUS_DONE,
  TASK_STATUS_TODO,
  TASK_TYPE,
  USER_NAME_NOT_ASSIGNED
} from '../../Constants'
import Moment from 'moment'
import Checkbox from 'material-ui/Checkbox'
import {RRule} from 'rrule'
import {
  addReceiptRepair,
  deleteReceiptRepair,
  deleteTaskCostApi,
  loadTasksById,
  processTaskApi,
  saveTaskCost,
  uploadRepairDoc
} from '../../Api'
import ModalCarSearch from './ModalCarSearch.jsx'
import isEmpty from 'lodash/isEmpty.js'
import Loader from '../../../common/UI/Loader.jsx'
import {browserHistory} from 'react-router'
import {getRandomId, getRepairsWithCosts, usdNumberToFormat} from '../../Utils'
import DPCost from '../../common/cost/DPCost'
import {deleteItemArray, formatToNumber, getErrorsInCosts, getFileNameAndType, getPositionById,} from '../util/Utils'
import {DPSectionHeader} from '../../common/DPSectionHeader'
import DPKeyboardDatePicker from '../../common/DPKeyboardDatePicker'
import {getPosition} from '../../../common/Util'
import {pathServer, SUCCESSFUL_CODE, colors} from '../../../common/Constants'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import {cloneDeep} from 'lodash'
import ModalConfirmDeactivate from '../util/ModalConfirmDeactivate'
import MessageBodyCustom from '../inventory/MessageBodyCustom'
import SubTask from './SubTask'
import {priorities} from "./TaskEdit";

const typeTask = [{id:"normal", displayValue:"Normal"},{id:"repair", displayValue:"Repair"}];

const elementCheck = (
  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_check_box.svg"} beforeInjection={ svg => svg.classList.add("icon-check")}/>
);

const elementUnCheck = (
  <ReactSVG src={pathServer.PATH_IMG + "icon/ic_uncheck_box.svg"} beforeInjection={ svg => svg.classList.add("icon-uncheck")}/>
);

const freqFrequency = [
  {id: 0, displayValue: "Year"},
  {id: 1, displayValue: "Month"},
  {id: 2, displayValue: "Week"},
  {id: 3, displayValue: "Day"}
];

const SECTIONS = {
  INFO: "information",
  FREQUENCY: "frequency",
  FILES: "files",
  STAGE: "stage",
}

function getTaskInitial() {
  return {title: "", typeTask: "normal", status: TASK_STATUS_TODO, priority: "low", description: "", taskParentId: null}
}

function getCostInitial(){
  return {description: 'New brakes', labor:'' , part: '', total: '',receipts: []}
}

function getRuleInitial(freq, interval, dtStart){
  return new RRule({freq: freq ,interval: interval, dtstart: dtStart});
}

function getRepairInitial() {
  return {priority: "low", description: "", typeRepair: TASK_TYPE.REPAIR, code: "", cost: 0, assignedTo: '', vendor: '', status: TASK_STATUS_TODO,
    carServices: null, carId: ''}
}

function getNameUserSelected(userName){
  let nameUSerSelected = "";
  if(userName){
    nameUSerSelected = userName && userName.length > 15 ? userName.substring(0,15) + "." : userName
  }else{
    nameUSerSelected = USER_NAME_NOT_ASSIGNED;
  }
  return nameUSerSelected;
}

class TaskDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      section: {
        information: true,
        frequency: true,
        files: true,
        stage: true,
        showPopover: false,
        taskInfo: {},
        taskFrequency: {},
        taskStage: {},
        newComment: ""
      },
      selectOpened: null,
      task: getTaskInitial(),
      cost: getCostInitial(),
      receipts: [],
      receiptsImages : [],
      template: {},
      comments: [],
      documents: [],
      frequency: getRuleInitial(0,0, new Date()),
      category: null,
      taskStage: {
        oilChange: false,
        emissions: false,
        newTires: false,
        carClean: false
      },
      typeValue: "Normal",
      statusValue: "To Do",
      priorityValue: "Low",
      frequencyValue: "Year",
      usersSelected: null,
      userAssigned: {},
      userComment: {},
      newComment: "",
      isLoading: false,
      errorShow:false,
      endsSelected: "never",
      frequencyUntil: new Date(),
      frequencyCount: 0,
      disabledFilter: false,
      showModalCarSearch:false,
      carSelected:"Select car",
      isMobilView:false,
      carOptions:null,
      carsAll:null,
      showPopoverCar:false,
      repair:getRepairInitial(),
      error:null,
      documentIds:[],
      optionSelected: null,
      carFound:{},
      costs: props.taskData.costs || [],
      subTasks: [],
    }
  }

  async componentDidMount() {
    await this.loadTaskId(this.props);

    const { taskData } = this.props;
    if (taskData && taskData.typeTask === 'repair') {
      await this.initializeFormData(this.props);
    }
  }

  componentDidUpdate(prevProps) {
    const {taskData} = this.props
    if ((taskData && taskData.typeTask === 'repair' && prevProps.taskData !== taskData)) {
      this.initializeFormData(this.props);
    }
  }

  /*
  UNSAFE_componentWillReceiveProps(newProps) {
    // Performing an action
  }
   */

  /*
  static getDerivedStateFromProps(nextProps, prevState) {
    console.log('Component is receiving new nextProps --->', nextProps.subTasks);
    console.log('Component is receiving new prevState --->', prevState.subTasks);
    if (nextProps.subTasks && nextProps.subTasks !== prevState.subTasks) {
      console.log('update subTasks --->')
      // this.setState({subTasks: nextProps.subTasks});
      return { subTasks: nextProps.subTasks }
      // this.initializeFormData(newProps);
      // this.loadTaskId(newProps)
    }
    return null
  }
   */

  reloadTask = () => {
    this.loadTaskId(this.props);
    // todo: load task info
    /*
    const { taskData } = this.props;
    if (taskData && taskData.typeTask === 'repair') {
      console.log('taskDetail - componentDidMount reloadTask - initializeFormData')
      this.initializeFormData(this.props);
    }
     */
  }

  getSubTasks = () => {
    return this.state.subTasks
  }

  calculateSubTasksCost = () => {
    const subtasksToCalculate = (this.props.subTasks?.length > 0) ? this.props.subTasks : this.state.subTasks || [];

    return subtasksToCalculate.reduce((acc, el) => {
      const cost = el.totalCost || el.repairCost?.totalCost|| 0;
      return acc + parseFloat(cost);
    }, 0.00);
  }

  initializeFormData(props) {
    const { taskData, users, cars, makes, models, statusesByType, subTasks } = props;

    // Only proceed if it's a repair task
    if (!taskData || taskData.typeTask !== 'repair') return;

    // Get basic task data
    const task = {
      ...this.state.task,
      title: taskData.title || '',
      description: taskData.description || '',
      typeTask: 'repair',
      status: taskData.status || TASK_STATUS_TODO,
      carId: taskData.carId || '',
      assignedUserId: taskData.assignedUserId || '',
      priority: taskData.priority || 'low',
      dueDate: taskData.dueDate || null
    };

    // Get assigned user and users list
    const usersSelected = users?.filter(u => u.active)
      .map(u => ({
        id: u.id,
        displayValue: `${u.firstName} ${u.lastName}`,
        image: u.image,
        initials: `${u.firstName[0]}${u.lastName[0]}`
      })) || [];

    const assignedUser = usersSelected.find(u => u.id === task.assignedUserId);
    const userAssigned = assignedUser ? {
      id: assignedUser.id,
      name: assignedUser.displayValue,
      image: assignedUser.image,
      initials: assignedUser.initials
    } : {};
    // Update state with all form values
    this.setState({
      task,
      typeValue: "Repair",
      statusValue: statusesByType.find(st => st.type === 'repair')?.statuses
        .find(s => s.id === task.status)?.displayValue || "To Do",
      carSelected: task.carId ? (() => {
        const car = cars?.find(c => c.id === task.carId);
        const make = makes?.find(m => m.id === car?.makeId);
        const model = models?.find(m => m.id === car?.modelId);
        return make && model ? `${make.name} ${model.name}` : "Select car";
      })() : "Select car",
      usersSelected,
      userAssigned,
      priorityValue: priorities.find(p => p.id === task.priority)?.displayValue || "Low",
      costs: taskData.repairCost || taskData.costs || [],
      comments: taskData.comments || [],
      subTasks: subTasks
    }, () => {
      this.props.updateTitleTask?.(task.title);
      this.props.updateEditTask?.(task.typeFilter);
    });
  }

  loadTaskId = (props) => {
    const {id,users,email,cars,makes ,models,type,statusesByType, carId: carIdFromProps, repairCost} = props;
    let userComment = {};
    let usersSelected = [];
    let carId = carIdFromProps ? carIdFromProps : ''

    if (users && cars && makes){
      const usersActive = users.filter(user => {return user.active === true});
      usersActive.forEach(user => {
        usersSelected.push({id: user.id, displayValue: user.firstName + ' ' + user.lastName, image: user.image, initials: user.firstName.substring(0,1) + user.lastName.substring(0,1)});
        if (user.email === email){
          userComment.id = user.id;
          userComment.image = user.image;
          userComment.initials = user.firstName.substring(0,1) + user.lastName.substring(0,1);
        }
      });

      if (id !== "new"){
          loadTasksById(id).then(({responseCode, data, responseMessage}) => {
            if (responseCode === SUCCESSFUL_CODE) {
              let {frequency, frequencyValue, endsSelected, frequencyUntil, frequencyCount, typeValue, statusValue,
                priorityValue, userAssigned,taskStage} = this.state;
              const { typeFilter, template, subTasks } = data.tasks;
              let { task, comments, costs } = data.tasks;

              if (props.taskData.id !== undefined) {
                task = {
                  ...task,
                  assignedUserId: props.taskData.assignedUserId || task.assignedUserId,
                  carId: props.taskData.carId || task.carId,
                  description: props.taskData.description || task.description,
                  dueDate: props.taskData.dueDate || task.dueDate,
                  id: props.taskData.id || task.id,
                  priority: props.taskData.priority || task.priority,
                  status: props.taskData.status || task.status,
                  title: props.taskData.title || task.title,
                  typeTask: props.taskData.typeTask || task.typeTask,
                }
              }
              let costsToSave = []
              if (props.taskData.repairCost !== undefined && props.taskData.repairCost.length > costs.length) {
                costsToSave = [...props.taskData.repairCost]
              } else {
                if(task.type === "repair"){
                  costsToSave = getRepairsWithCosts(costs)
                }
              }

              if (props.taskData.comments !== undefined && props.taskData.comments.length > comments.length) {
                comments = [...props.taskData.comments]
              }

              let templateToSave = {}


              if (template){
                templateToSave = template;
                let stringFrequency = template.frequency;
                frequency = RRule.fromString(stringFrequency);

                if (frequency && frequency.origOptions.until){
                  endsSelected = "on";
                  frequencyUntil = frequency.origOptions.until;
                } else if (frequency && frequency.origOptions.count){
                  endsSelected = "after";
                  frequencyCount = frequency.origOptions.count;
                }

                freqFrequency.forEach(time => {
                  if (time.id === frequency.origOptions.freq)
                    frequencyValue = time.displayValue;
                });
              }

              typeTask.forEach(type => {
                if (type.id === task.type){
                  typeValue = type.displayValue;
                  task.typeTask = type.id;
                }
              });

              const selectedStatusesByType = statusesByType.find(st => { return st.type === task.type  })
              const selectedStatuses = selectedStatusesByType.statuses

              selectedStatuses.forEach(status => {
                if (status.id === task.status)
                  statusValue = status.displayValue;
              });

              if(task.assignedUserId !== ""){
                usersSelected.forEach(user => {
                  if (user.id === task.assignedUserId){
                    userAssigned.id = user.id;
                    userAssigned.name = user.displayValue;
                    userAssigned.image = user.image;
                    userAssigned.initials = user.initials;
                  }
                });

                if(!userAssigned.id){
                  const userFound = users.find(user => {return user.id === task.assignedUserId});
                  if(userFound){
                    userAssigned.id = userFound.id;
                    userAssigned.name = userFound.firstName + ' ' + userFound.lastName;
                    userAssigned.image = userFound.image;
                    userAssigned.initials = userFound.firstName.substring(0,1) + userFound.lastName.substring(0,1);
                  }
                }
              }

              priorities.forEach(priority => {
                if (priority.id === task.priority)
                  priorityValue = priority.displayValue;
              });

              let carSelected = "Select car";
              let carFound = {}

              if( task.type === 'repair'){
                carId = task?.carId
                task.carId = carId

                carFound = cars.find(car => {return car.id === carId});
                const makeFound = makes.find(make => {return make.id === carFound.makeId});
                const modelFound = models.find(model => {return model.id === carFound.modelId});
                carSelected = `${makeFound.name} ${modelFound.name}`;

              }

              if(typeFilter === FILTER_TYPE_TASK[0].value || typeFilter === FILTER_TYPE_TASK[1].value || typeFilter === FILTER_TYPE_TASK[2].value){
                task.typeFilter = typeFilter;
              }

              this.setState({task, comments, template: templateToSave, typeValue: typeValue, statusValue, usersSelected: usersSelected, userAssigned, priorityValue, frequency,
                frequencyValue, endsSelected, frequencyUntil, frequencyCount, disabledFilter:true, userComment: userComment,carSelected,taskStage,
                carFound, costs: costsToSave, selectedStatuses, subTasks})
              this.props.updateTitleTask(task.title)
              this.props.updateEditTask(typeFilter)
              } else {
              console.error(responseMessage)
              }
          })
      } else {
        let costsToSave = this.props.taskData.repairCost || []

        let { task } = this.state
        task.typeTask = type
        let typeValue = ""
        let statusValue = ""

        let carSelected = "Select car"
        let carFound = {}
        if(!isEmpty(carId)){
          carFound = cars.find(car => {return car.id === carId})
          const makeFound = makes.find(make => {return make.id === carFound.makeId})
          const modelFound = models.find(model => {return model.id === carFound.modelId})
          carSelected = `${makeFound.name} ${modelFound.name}`
          task.title = carFound.exteriorColor ? `${carFound.year} ${carSelected} - ${carFound.exteriorColor} - ${carFound.vin}` : `${carFound.year} ${carSelected} - ${carFound.vin}`;
          task.carId = carId
        }
        typeTask.forEach(option => {
          if (option.id === task.typeTask)
            typeValue = option.displayValue
        });

        const selectedStatusesByType = statusesByType.find(st => { return st.type === type  })
        const selectedStatuses = selectedStatusesByType.statuses
        statusValue = selectedStatuses[0].displayValue
        /*
        let costs = []
        if(repairCost) {
          costs = [repairCost]
        }
         */
        this.setState({usersSelected: usersSelected, userComment: userComment, task, typeValue, statusValue, carSelected,
          carFound, costs: costsToSave, selectedStatuses});
      }
    }

  }

  accordionToggle = (id) => {
    let {section} = this.state;
    section[id] = !section[id];
    this.setState({section: section});
  }

  handlePopover = (id, event) => {
    event.preventDefault();
    let {task, usersSelected, typeValue, statusValue, priorityValue, userAssigned, frequencyValue, selectedStatuses} = this.state;
    const {statusesByType} = this.props
    let options = [];
    let category = "";
    let optionSelected = "";
    let anchorEl = getPositionById(id, true)

    switch (id) {
      case "typeTask":
        options = typeTask;
        category = "info";
        optionSelected = typeValue;
        break;
      case "priority":
        options = priorities;
        category = "info";
        optionSelected = priorityValue;
        break;
      case "assignedUserId":
        options = usersSelected;
        category = "info";
        optionSelected = userAssigned.id;
        break;
      case "status":
        category = "info";
        options = selectedStatuses
        optionSelected = statusValue;
        break;
      case "freq":
        options = freqFrequency;
        category = "frequency";
        optionSelected = frequencyValue;
        break;
    }

    this.setState({anchorEl: anchorEl, showPopover: true, selectOpened: id, options:options, category: category, optionSelected: optionSelected});
  }

  handleModal = (event) => {
    event.preventDefault();
    this.setState({showModalCarSearch:!this.state.showModalCarSearch,selectOpened:"cardId",anchorEl: event.currentTarget});
  }

  handlePopoverCar = (event) => {
    event.preventDefault();
    let {carsAll} = this.state;
    const {cars,makes,models} = this.props;
    let carsData = [];
    let makeFound;
    let modelFound;

    if(!carsAll){
      const carsFiltered = cars.filter(car => { return car["car.status"] && car["car.status"].attributeValue !== carStatus.SOLD && car["car.status"].attributeValue !== carStatus.ARCHIVED});
      carsFiltered.forEach(car => {
        makeFound = makes.find(make => {
          return make.id === car.makeId
        });
        modelFound = models.find(model => {
          return model.id === car.modelId
        });

        carsData.push({
          id: car.id,
          makeName: makeFound.name,
          modelName: modelFound.name,
          year: car.year,
          description: car.description,
          mileage:car.mileage,
          displayValue:makeFound.name +" "+ modelFound.name +" "+car.year
        })
      });
    }else{
      carsData = carsAll;
    }

    let pointTo = getPosition(event.currentTarget);
    let anchorEl = {
      x: pointTo.x,
      y: pointTo.y - 25,
      offsetWidth: 230
    };

    let showModalCarSearch = true;
    let isMobilView = false;
    if(window.innerWidth <= 1024){
      if(window.innerWidth > 600)
        anchorEl.x = anchorEl.x - 65;
      isMobilView = true;
      showModalCarSearch = false;
    }

    this.setState({selectOpened:"carId",anchorEl: anchorEl,carOptions:carsData,carsAll:carsData,showPopoverCar:true,
      isMobilView:isMobilView,showModalCarSearch:showModalCarSearch});
  }

  handleRequestClose = () => {
    this.setState({
      showPopover: false,
      selectOpened: null,
      showModalCarSearch:false,
      showPopoverCar:false
    });
  }

  handleChangeDate = (id, newDate) => {
    let {task, frequency, frequencyUntil} = this.state;
    if(id === "dueDate"){
      task.dueDate = Moment(newDate).format("MM/DD/YYYY");
    }
    if(id === "onDate"){
      frequencyUntil = Moment(newDate).format("MM/DD/YYYY");
      frequency.origOptions.until = Moment(newDate).format("MM/DD/YYYY");
    }

    this.setState({task, frequency, frequencyUntil});
    this.props.handleUpdateData("hasChange");
  }

  handleBlurDate(id, index, event) {
    this.setState({date: event.target.value}, () => {
      this._onChange(id, index)
    });
  }

  handleCheckTaskFrequencyEnds = (type, evt) => {
    const id = evt.target.id;
    let {endsSelected, frequency, frequencyUntil, frequencyCount} = this.state;
    if (endsSelected === id)
      endsSelected = "";
    else
      endsSelected = id;

    if (type === "never"){
      frequency = getRuleInitial(frequency.origOptions.freq, frequency.origOptions.interval, frequency.origOptions.dtstart);
    } else if (type === "on") {
      frequency = getRuleInitial(frequency.origOptions.freq, frequency.origOptions.interval, frequency.origOptions.dtstart);
      frequency.origOptions.until = frequencyUntil;
    } else {
      frequency = getRuleInitial(frequency.origOptions.freq, frequency.origOptions.interval, frequency.origOptions.dtstart);
      frequency.origOptions.count = frequencyCount;
    }

    this.setState({endsSelected,frequency});
    this.props.handleUpdateData("hasChange");
  }

  handleCheckTaskFrequency = (type, evt) => {
    let {template} = this.state;
    template.active = evt.target.checked;
    if(!evt.target.checked){
      delete template.active;
    }
    this.setState({template});
    this.props.handleUpdateData("hasChange");
  }

  onChangeInputNumber = (evt) => {
    let {frequency, frequencyCount} = this.state;
    let id = evt.target.id;
    let value = evt.target.value.replace(" ","");
    if(!isNaN(value)){
      if(id === "number")
        frequency.origOptions.interval = value;
      if(id === "numberAfter"){
        frequencyCount = value;
        frequency.origOptions.count = frequencyCount;
      }
      this.setState({frequency, frequencyCount});
      this.props.handleUpdateData("hasChange");
    }
  }

  onChangeTextTaskInfo = (evt) => {
    let {task} = this.state;
    let id = evt.target.id;
    let value = evt.target.value;
    task[id] = value;

    this.setState({task,error:null});
    this.props.handleUpdateData("hasChange");
  }

  onClickPopover = (id) => {
    let {selectOpened, task, userAssigned, usersSelected, category, frequency, repair, typeValue} = this.state;
    const {statusesByType} = this.props

    let state = {showPopover: false, selectOpened: ""}

    if (category === "info") {
      task[selectOpened] = id;
      state.task = task
      if(task.typeTask === TASK_TYPE.REPAIR && selectOpened === "assignedUserId"){
        repair.assignedTo = id;
        state.repair = repair
      }

    } else if(category === "frequency"){
      frequency.origOptions[selectOpened] = id;
      state.frequency = frequency
    }

    if(selectOpened === "typeTask"){
      typeTask.forEach(type => {
        if (type.id === id){
          state.typeValue = type.displayValue
        }
      });

      if (state.typeValue !== typeValue){
        const statusByType = statusesByType.find(st => { return st.type === id })
        state.statusValue = statusByType.statuses[0].displayValue;
        state.selectedStatuses = statusByType.statuses
      }

    }

    if(selectOpened === "status"){
      const statusByType = statusesByType.find(st => { return st.type === task.typeTask })
      statusByType.statuses.forEach(status => {
        if (status.id === id){
          state.statusValue = status.displayValue;
        }
      })
    }

    if(selectOpened === "assignedUserId"){
      usersSelected.forEach(user => {
        if (user.id === id){
          userAssigned.name = user.displayValue;
          userAssigned.image = user.image;
          state.userAssigned = userAssigned
        }
      })
    }

    if(selectOpened === "priority"){
      priorities.forEach(priority => {
        if (priority.id === id)
          state.priorityValue = priority.displayValue;
      })
    }

    if(selectOpened === "freq"){
      freqFrequency.forEach(frequency => {
        if (frequency.id === id){
          state.frequencyValue = frequency.displayValue;
        }
      })
    }

    this.setState(state);
    this.props.handleUpdateData("hasChange");
  }


  /*** on Drop Files***/
  onDrop = (files) => {
    let {documents} = this.state;
    files.map((file, index) => {
      const {name,type} = getFileNameAndType(file.name);
      documents.push({url: URL.createObjectURL(file), name: name + "." + type, weight: file.size,file:file});
    });

    this.setState({documents});
    this.props.handleUpdateData("hasChange");
  }

  deleteFile = (index, evt) => {
    evt.stopPropagation();
    let {documents,documentIds} = this.state;
    if(documents[index].id){
      documentIds.push(documents[index].id);
    }
    documents.splice(index,1);
    this.setState({documents,documentIds});
    this.props.handleUpdateData("hasChange");
  }

  onChangeNewComment = (evt) => {
    let newComment = evt.target.value;
    this.setState({newComment});
    this.props.handleUpdateData("hasChange");
  }

  addNewComment(){
    let {comments, newComment, userComment} = this.state;
    if(newComment.trim() !== ""){
      comments.push({comment: newComment.trim(), userId: userComment.id});
    }
    this.setState({comments, newComment: ""});
    this.props.handleUpdateData("hasChange");
  }

  getError = () => {
    let {task, costs} = this.state;
    let error = {};
    if(task.title === '')
      error.title = true;

    if(task.typeTask === TASK_TYPE.REPAIR && isEmpty(task.carId))
      error.carId = true;

    const costErrors = getErrorsInCosts(costs)
    if(costErrors.length > 0)
    error.costs = costErrors

    return error;

  }

  setError = () => {
    let error = this.getError();
    if(!isEmpty(error)){
      this.setState({error:error});
    }
  }

  onDropPhoto = (file,url) => {
    file.url=url;
    this.setState({receiptsImages: [...this.state.receiptsImages, file]})
    this.props.handleUpdateData("hasChange");
  }

  deleteReceipt = (id) => {
    let {receiptsImages} = this.state;
    deleteReceiptRepair(id).then( () => {
      receiptsImages = deleteItemArray(id,receiptsImages);
      this.setState({receiptsImages});
    }).catch((error) =>{
    })
  }

  updateCosts = (index, newRepairId, receipts) => {
    const { costs } = this.state
    let costsToUpdate = cloneDeep(costs)
    let costFound = costsToUpdate[index]
    costFound = {...costFound, id: newRepairId, receipts: (receipts) ? receipts : costFound.receipts}
    costsToUpdate[index] = costFound
    this.setState({costs: costsToUpdate, isLoading: false})
  }

  onSaveReceipts = async (receipts, repairId, index) => {
    if(repairId){
      this.setState({isLoading: true})
      const response = await addReceiptRepair(receipts,repairId);
      const {responseCode, data} = response
      if (responseCode === SUCCESSFUL_CODE) {
        this.setState({receiptsImages: data.receipts, isLoading: false})
        this.updateCosts(index, repairId, data.receipts)
      }
    }
  }

  addCostToRepair = (cost) => {
    let arrCost = [];
    const fields = [COST_REPAIR_LABOR, COST_REPAIR_PART];
    const costInitial = getCostInitial();
    cost && fields.map( f => {
      const objCost = {};
      if (cost.laborId && f === COST_REPAIR_LABOR) {
        objCost.id = cost.laborId;
      }
      if (cost.partId && f === COST_REPAIR_PART) {
        objCost.id = cost.partId;
      }
      objCost.costType = f;
      objCost.costValue = f === COST_REPAIR_LABOR ? formatToNumber(cost.labor) : formatToNumber(cost.part);
      if (cost.labor !== costInitial.labor || cost.part !== costInitial.part) {
        arrCost.push(objCost);
      }
    });
    return arrCost;
  }

  saveCost = async (index,repairId) => {
    const {cost, repair} = this.state;
    let arrCost = this.addCostToRepair();
    let payLoad= [{
      id: repairId,
      carId : repair.carId,
      description: cost.description,
      costs: arrCost,
    }];
    if(!repairId){
      const response = await saveTaskCost({listRepair: payLoad})
      const {responseCode, data} = response
      if (responseCode === SUCCESSFUL_CODE) {
        const newRepairId = data.repairs[0].id
        cost.id = newRepairId;
        this.setState({cost});
        this.props.updateRepairId(newRepairId)
      }
    } else {
      payLoad = [{
        id: repairId,
        ...payLoad[0]
      }];
      await saveTaskCost({listRepair: payLoad})
    }
  }

  validationData( carIdFromParams, repairId){
    let {task, template, comments, frequency,taskStage,repair,documents,documentIds} = this.state;
    const carId = carIdFromParams ? carIdFromParams : task.carId
    if(task.dueDate){
      const dueDate = Moment(task.dueDate).format("MM/DD/YYYY");
      task.dueDate = new Date(dueDate).getTime();
    }

    if(!task.completionDate){
      if(task.status === TASK_STATUS_DONE || task.status === TASK_STATUS_COMPLETE){
        task.completionDate = new Date().getTime();
      }else{
        delete task.completionDate;
      }
    }else{
      if(task.status !== TASK_STATUS_DONE && task.status !== TASK_STATUS_COMPLETE){
        delete task.completionDate;
      }
    }

    if(template.active){
      template.content = JSON.stringify({assignedUserId: task.assignedUserId,
                                        title: task.title,
                                        description: task.description,
                                        typeTask: task.typeTask,
                                        priority: task.priority,
                                        status: task.status}) ;
      template.frequency = frequency.toString();
    }

    let requestForm = {};
    if (template.active && (comments.length > 0)){
      requestForm = {task: task, template: template, comments: comments}
    } else if (template.active && (comments.length === 0)){
      requestForm = {task: task, template: template}
    } else if (!template.active && (comments.length > 0)){
      requestForm = {task: task, comments: comments}
    } else {
      requestForm = {task: task}
    }

    if(task.typeTask === TASK_TYPE.REPAIR){
      let carServices = [];
      for(let key in taskStage){
        if(taskStage[key] === true)
          carServices.push(key);
      }
      repair.carServices = JSON.stringify(carServices);
      //requestForm.repair = repair;
    }

    this.setState({isLoading: true});
    processTaskApi(requestForm).then(({responseCode,data,responseMessage}) => {
      if (responseCode === SUCCESSFUL_CODE) {
        const {taskId, repairId} = data;
        let documentsToInsert = [];
        documents.forEach(doc => {
          if(!doc.id)
            documentsToInsert.push(doc)
        });

        if (documentsToInsert.length > 0 || documentIds.length > 0) {
          uploadRepairDoc({taskId: taskId, documents: documentsToInsert, documentIds: documentIds}).then((response) => {
            const {responseCode, responseMessage} = response
            if (responseCode === SUCCESSFUL_CODE) {
              this.setState({isLoading: false})
              if (task.typeTask === TASK_TYPE.REPAIR) {
                this.saveAllCost(carId, taskId)
              }
            } else {
              console.error(responseMessage)
              this.setState({isLoading: false})
            }
          })
        } if (task.typeTask === TASK_TYPE.REPAIR) {
          this.setState({isLoading: false})
          this.saveAllCost(carId, taskId)
          browserHistory.push({pathname: '/main/task'})
        } else {
          this.setState({isLoading: false})
          browserHistory.push({pathname: '/main/task'})
        }
      } else {
        this.setState({errorShow:true,errorMessage:responseMessage,isLoading: false});
        setTimeout(function () {
          this.setState({errorShow:false});
        }.bind(this), 2500);
      }
    });
  }

  parseDateString = (dateString) => {
    const [month, day, year] = dateString.split('/').map(Number);
    return new Date(year, month - 1, day).getTime();
  }
  saveTaskSubTasks = (subTasks)  => {

    const subTasksForRequest = subTasks.map((subTask) => ({
      task: {
        id: null,
        assignedUserId: subTask.task?.assignedUserId || subTask.assignedUserId || undefined,
        typeTask: subTask.task?.typeTask || subTask.typeTask || "",
        priority: subTask.task?.priority || subTask.priority || "low",
        title: subTask.task?.title || subTask.title || "",
        description: subTask.task?.description || subTask.description || null,
        dueDate: (subTask.task?.dueDate || subTask.dueDate ) ? new Date(subTask.task?.dueDate || subTask.dueDate ).getTime() : null,
        completionDate: (subTask.task?.status || subTask.status) === "done"
          ? subTask.task?.completionDate || subTask.completionDate || Date.now()
          : subTask.task?.completionDate || subTask.completionDate || null,
        status: subTask.task?.status || subTask.status || "todo",
        typeReferenceId: null,
        carId: subTask.task?.carId || subTask.carId || null,
        templateId: null
      },
      repairCost: subTask.repairCost ? subTask.repairCost : undefined,
      comments: Array.isArray(subTask.comments) ? subTask.comments : undefined
    }));

    let {task, template, comments, frequency,taskStage,repair,documents,documentIds} = this.state;

    if(task.dueDate){
      const dueDate = Moment(task.dueDate).format("MM/DD/YYYY");
      task.dueDate = new Date(dueDate).getTime();
    }

    if(!task.completionDate){
      if(task.status === TASK_STATUS_DONE){
        task.completionDate = new Date().getTime();
      }
    }else{
      if(task.status !== TASK_STATUS_DONE && task.status !== TASK_STATUS_COMPLETE){
        delete task.completionDate;
      }
    }

    let requestForm = {};
    requestForm = {
      task: {...task,
        assignedUserId: task.assignedUserId || undefined,
      },
      template: template.isActive ? template : undefined,
      comments: comments.length > 0 ? comments : undefined ,
      subTasks: subTasks.length > 0 ? subTasksForRequest : []
    }
    /*
    let {task, template, comments} = this.state;
    const requestForm = { task,
      template: template.isActive ? template : undefined, comments: comments.length > 0 ? comments : undefined , subTasks: subTasks.length > 0 ? subTasksForRequest : []}

     */


    processTaskApi(requestForm).then(({responseCode,data,responseMessage}) => {
      if (responseCode === SUCCESSFUL_CODE) {
        const {taskId, repairId} = data;
        if (task.typeTask === TASK_TYPE.REPAIR) {
          this.setState({isLoading: false})
          this.saveAllCost(task.carId, taskId)
        }
      }
    })
    browserHistory.push({pathname: '/main/task'})

  }

  getImageOrInitials(id){
    const {usersSelected} = this.state;
    let imageOrInitials = "";

    usersSelected.forEach(user => {
      if (user.id === id){
        if (user.image !== "")
          imageOrInitials = <img className={'image-user-reply'} src={user.image}/>;
        else
          imageOrInitials = user.initials;
      }
    });

    return imageOrInitials;
  }

  handleCarSelect = ( car ) => {
    let {task,repair} = this.state;
    task.title = car.exteriorColor ? `${car.year} ${car.makeName} ${car.modelName} - ${car.exteriorColor} - ${car.vin}` : `${car.year} ${car.makeName} ${car.modelName} - ${car.vin}`;
    task.carId = car.id;
    repair.description = `${car.year} ${car.makeName} ${car.modelName}`;
    repair.carId = car.id;
    this.setState({carId:car.id,carSelected:`${car.makeName} ${car.modelName}`,showModalCarSearch:false,task:task,
      showPopoverCar:false,selectOpened: null,error:null});
    this.props.handleUpdateData("hasChange");
  }

  findByKeyWord = (evt) => {
    const keyword = evt.target.value;
    const {carsAll} = this.state;
    let carsFiltered = carsAll;
    if(keyword && keyword !== ''){
      carsFiltered = carsFiltered.filter(car => {
        return car.makeName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 || car.modelName.toLowerCase().indexOf(keyword.toLowerCase()) !== -1 ||
          car.year.toString().toLowerCase().indexOf(keyword.toLowerCase()) !== -1 || car.description.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
      });
    }

    this.setState({carOptions:carsFiltered});
  }

  goToDocument(url,evt){
    evt.preventDefault();
    window.open(url,"_blank");
  }

  onChangeCost = (costParam,index) => {
   const {costs, error} = this.state;
    costs[index] = costParam
    const newError = {...error, costs : []}
    this.setState({costs:[...costs], error: newError});
    this.props.handleUpdateData("hasChange");
  }

  costOnBlur = (index, e) => {
    let {costs} = this.state;
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case 'labor':
        costs[index]['part'] = usdNumberToFormat(costs[index]['part']);
        break;
      case 'part':
        costs[index]['labor'] = usdNumberToFormat(costs[index]['labor']);
        break;
    }

    costs[index][name] = usdNumberToFormat(value)

    this.setState({costs : [...costs]});
    this.props.handleUpdateData("hasChange");
  }

  renderOptions(){
    let {options,optionSelected,selectOpened, assignedUserId} = this.state;
    let values = [];

    if(options && options.length > 0){
      options.map((op, index) => {
        const addAnotherClassFlag = ['low', 'medium', 'high'].includes(op.displayValue.toLowerCase())
        const classOption = `change-submenu-item ${addAnotherClassFlag ? op.displayValue.toLowerCase().trim() : ''}`.trim()
        const value = selectOpened === 'assignedUserId' ? op.id : op.displayValue
        if(value === optionSelected){
          values.push(
            <li key={index} className="item-selected" onClick={() => this.onClickPopover(op.id)} data-test={`option-${index}`}>
              {op.displayValue}
            </li>
          )
        } else {
          values.push(
            <li key={index} className={classOption} onClick={() => this.onClickPopover(op.id)} data-test={`option-${index}`}>
              {op.displayValue}
            </li>
          )
        }
      });
    }

    return (
      <div className="popover-general-message">
        <div className="popover-general-message-sub">
          <u>
            {values}
          </u>
        </div>
      </div>
    )
  }

  addNewCost = () => {
    const defaultCost = {
      description: '',
      part: '',
      labor: '',
      total: '',
      receipts: [],
      temporalId: getRandomId(),
    };

    this.setState({costs: [...this.state.costs, defaultCost]})
  }

  deleteCost = (index, costId) => {
    const { costs } = this.state

    if(costId) {
      deleteTaskCostApi(costId, "task").then((response) => {
        const { responseCode, isAttached, receipts } = response
        if (responseCode !== SUCCESSFUL_CODE) {
          return
        }
        if(!isAttached){
          const newCosts = this.removeByIndex(costs, index)
          this.setState({costs: newCosts})
        }else{
          this.setState({
            isCostAttached: isAttached,
            attachedReceipts: receipts ? receipts : null,
          })
        }

      })
    } else {
      const costsNew = cloneDeep(costs)
      costsNew.splice(index, 1)
      this.setState({costs: costsNew})
    }
  }

  removeByIndex = (list, indexToRemove) => {
    return [...list.slice(0, indexToRemove), ...list.slice(indexToRemove + 1)]
  }

  saveAllCost = async (carId, taskId) => {
    const {costs} = this.state;
    const payload = costs.map(cost => {
      let costOjb = {costs: this.addCostToRepair(cost), carId: carId, description: cost.description}
      if(cost?.id)
        costOjb.id = cost?.id
      return costOjb
    });
    return await saveTaskCost({listRepair: payload, taskId: taskId})
  }

  hideModal = () => {
    this.setState({isCostAttached: false})
  }

  handleDeleteSubTask = (subtask) => {

    //this.props.deleteSubTask(subtask)
  }

  render() {
    let {section, selectOpened, showPopover, anchorEl, taskStage, newComment, task, documents,receiptsImages, comments, template, typeValue, statusValue,
      priorityValue, userAssigned, frequency, frequencyValue, endsSelected, frequencyUntil, frequencyCount, disabledFilter, userComment,
      showModalCarSearch,carSelected,showPopoverCar,isMobilView,carOptions,error,isLoading,costs, isCostAttached, attachedReceipts, subTasks} = this.state;
    const { cars,makes,models,locations,id, repairCost, openDeleteModal } = this.props;

    const isDisabled = task.typeFilter === FILTER_TYPE_TASK[1].value || task.typeFilter === FILTER_TYPE_TASK[2].value;

    if(id !== "new" && !task.id) {
      return null;
    }

    const element = <MessageBodyCustom vin={carSelected.vin} isCostAttached={isCostAttached} attachedReceipts={attachedReceipts} />

    const renderFilter = (
      <div className="popover-body-car">
        <div className="menu-item-search">
          <ReactSVG src={pathServer.PATH_IMG + "icon/ic_search.svg"} className="car-search" beforeInjection={ svg => svg.classList.add("ic-car-search")}/>
          <input className="" onChange={this.findByKeyWord}
                 placeholder="Year, Make, Model..."/>
        </div>
        <div className="menu-item-list">
          {
            carOptions ?
              carOptions.map((car) => {
                return (
                  <a key={car.id} onClick={() => this.handleCarSelect(car)}>
                    {car.displayValue}
                  </a>
                )
              }) : null
          }
        </div>
      </div>
    );
    const childOptions = this.renderOptions();
    const enabledSelect = !disabledFilter && !repairCost

    return (
      <div className="body-edit-task">
        <div className='body-content-task'>
        {isLoading ? <Loader/> : null}
        <div className={isDisabled ? "task-info-disabled" : "task-info"}>
          <DPSectionHeader
            isClosed={!section[SECTIONS.INFO]}
            title="Task Info"
            accordionToggle={() => this.accordionToggle(SECTIONS.INFO)}
          />
          <div
            className="body-task-info"> {/*style={section[SECTIONS.INFO] ? {display: "flex"} : {display: "none"}}*/}
            {/*<div className=""> /!*"task-info-column-tit-desc"*!/*/}
              <div className="task-info-title">
                <div>
                  <label className="title">Task Title</label><label className="validation-field">*</label>
                </div>
                <input id="title" type="text" className="task-input" value={task.title}
                       onChange={this.onChangeTextTaskInfo}
                       style={(error && error.title) ? {borderColor: colors.errorLabel} : null}
                       maxLength="99"
                />
              </div>
              <div className="task-info-description">
                <label className="title">Task Description</label>
                <TextareaAutosize
                  className={'task-input non-resize'}
                  rowsMax={10}
                  rowsMin={5}
                  aria-label="maximum height"
                  value={task.description}
                  onChange={this.onChangeTextTaskInfo}
                  id={'description'}
                />
              </div>
            {/*</div>*/}
            {/*<div className="">/!*"task-info-column"*!/*/}
                <div className="task-info-type">
                  <label className="title">Type</label>
                  <div id="typeTask" className={(enabledSelect) ? "task-select" : "task-select-disabled"}
                       onClick={enabledSelect ? (event) => this.handlePopover("typeTask", event) : null }>
                    <label>{typeValue}</label>
                    {enabledSelect
                        ? selectOpened === "typeTask" ?
                            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                      className="icon-arrow-content"
                                      beforeInjection={svg => svg.classList.add("icon-arrow-expand")}/> :
                            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                      className="icon-arrow-content"
                                      beforeInjection={svg => svg.classList.add("icon-arrow-expand")}/>
                        : null
                    }
                  </div>
                </div>
                <div className="task-info-status">
                  <label className="title">Status</label>
                  <div id="status"
                       className={(subTasks && subTasks.length === 0) ? "task-select" : "task-select-disabled"}
                       onClick={(subTasks && subTasks.length === 0) ? (event) => this.handlePopover("status", event) : null}>
                    <label>{statusValue}</label>
                    {(subTasks && subTasks.length === 0)
                        ? selectOpened === "status" ?
                            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                      className="icon-arrow-content"
                                      beforeInjection={svg => svg.classList.add("icon-arrow-expand")}/> :
                            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                      className="icon-arrow-content"
                                      beforeInjection={svg => svg.classList.add("icon-arrow-expand")}/>
                        : null
                    }
                  </div>
                </div>
                {
                  task.typeTask === TASK_TYPE.REPAIR ?
                    <div className="task-info-car">
                      <label className="title">Car To Repair</label><label className="validation-field">*</label>
                      <div id="carId"
                           className={!repairCost && enabledSelect ? "task-select" : "task-select-disabled"}
                           onClick={enabledSelect ? this.handlePopoverCar : null}
                           style={(error && error.carId) ? {borderColor: colors.errorLabel} : null}>
                        <label>{(carSelected.length > 16) ? carSelected.substring(0, 16) + "." : carSelected}</label>
                        {enabledSelect
                            ? selectOpened === "carId" ?
                                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                          className="icon-arrow-content"
                                          beforeInjection={svg => svg.classList.add("icon-arrow-expand")}/> :
                                <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                          className="icon-arrow-content"
                                          beforeInjection={svg => svg.classList.add("icon-arrow-expand")}/>
                            : null
                        }
                      </div>
                    </div> : null
                }
                <div className="task-info-assigned">
                  <label className="title">Assigned to</label>
                  <div id="assignedUserId" className="task-select-assigned"
                       onClick={(event) => this.handlePopover("assignedUserId", event)}>
                    <img className="user-img"
                         src={userAssigned.image ? userAssigned.image : pathServer.PATH_IMG+"ic_account_circle.png"}
                         style={{width: 21, borderRadius: "50%"}}/>
                    <label>{getNameUserSelected(userAssigned.name)}</label>
                    {selectOpened === "assignedUserId" ?
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                className="icon-arrow-content"
                                beforeInjection={svg => svg.classList.add("icon-arrow-expand")}/> :
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                className="icon-arrow-content"
                                beforeInjection={svg => svg.classList.add("icon-arrow-expand")}/>
                    }
                  </div>
                </div>
                <div className="task-info-priority">
                  <label className="title">Priority level</label>
                  <div id="priority" className="task-select"
                       onClick={(event) => this.handlePopover("priority", event)}>
                    <label className={priorityValue.toLowerCase()}>{priorityValue}</label>
                    {selectOpened === "priority" ?
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                className="icon-arrow-content"
                                beforeInjection={svg => svg.classList.add("icon-arrow-expand")}/> :
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                className="icon-arrow-content"
                                beforeInjection={svg => svg.classList.add("icon-arrow-expand")}/>
                    }
                  </div>
                </div>
                <div className="task-info-dueDate">
                  <label className="title">Due date</label>
                  <DPKeyboardDatePicker
                    id={'dueDate'}
                    name={'dueDate'}
                    className="task-dueDate"
                    value={task.dueDate ? new Date(task.dueDate) : null}
                    onChange={(date) => this.handleChangeDate('dueDate', date)}
                  />
                </div>

            {/*</div>*/}
          </div>
          {
            (task.typeTask === TASK_TYPE.REPAIR && section[SECTIONS.INFO] ) &&
              <DPCost showTitleHeader={true}
                      showActionFooter={true}
                      costList={costs}
                      openRecipes={(index,repairId) => this.saveCost(index,repairId)}
                      onChange={this.onChangeCost}
                      isCarOrTask='T'
                      onDropPhoto={this.onDropPhoto}
                      receipts={receiptsImages}
                      deleteReceipt={this.deleteReceipt}
                      onSaveReceipts={this.onSaveReceipts}
                      costOnBlur={this.costOnBlur}
                      addNewCost={this.addNewCost}
                      removeTaskRepair={this.deleteCost}
                      showActionRow={true}
                      errors={error?.costs ?? []}
                      subTasksCost={this.calculateSubTasksCost()}
              />
          }
        </div>
        {
          task.typeTask === TASK_TYPE.NORMAL ?
              <div className={isDisabled ? "task-frequency-disabled" : "task-frequency"}>
              <DPSectionHeader
                isClosed={!section[SECTIONS.FREQUENCY]}
                title="Task Frequency"
                accordionToggle={() => this.accordionToggle(SECTIONS.FREQUENCY)}
              />

              <div className="body-task-frequency"
                   style={section[SECTIONS.FREQUENCY] ? {display: "flex"} : {display: "none"}}>
                <div className="row-container">
                  <Checkbox label="Frequent Task"
                            checked={template.active ? template.active : false}
                            id="frequency"
                            onCheck={(evt) => this.handleCheckTaskFrequency("frequency", evt)}
                            labelStyle={{color: "#787878", fontSize: 13, fontWeight: "400", fontFamily: "'Open Sans', sans-serif"}}
                            checkedIcon={elementCheck}
                            uncheckedIcon={elementUnCheck}
                            iconStyle={{borderColor: "#777", marginRight: 8}}/>
                </div>
                <div className="row-container">
                  <label className="task-freq-label">Repeat every</label>
                  <InputNumber idParent="freq-task-every"
                               id="number"
                               parentStyle="task-freq-parent-input"
                               value={frequency.origOptions.interval}
                               dynamicNumberStyle="freq-task-input"
                               onChange={this.onChangeInputNumber}
                               readOnly={!template.active}
                  />
                  <div id="freq" className="task-freq task-select"
                       onClick={template.active ? (event) => this.handlePopover("freq", event) : null}>
                    <label>{frequencyValue}</label>
                    {selectOpened === "frequentTask" ?
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_less.svg"}
                                className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/> :
                      <ReactSVG src={pathServer.PATH_IMG + "icon/ic_expand_more.svg"}
                                className="icon-arrow-content" beforeInjection={ svg => svg.classList.add("icon-arrow-expand")}/>
                    }
                  </div>
                </div>
                <div className="row-container">
                  <label className="task-freq-label">Ends</label>
                </div>
                <div className="row-container">
                  <Checkbox label="Never"
                            checked={endsSelected === "never"}
                            id="never"
                            onCheck={(evt) => this.handleCheckTaskFrequencyEnds("never", evt)}
                            labelStyle={{color: "#787878", fontSize: 13, fontWeight: "400", fontFamily: "'Open Sans', sans-serif"}}
                            checkedIcon={elementCheck}
                            uncheckedIcon={elementUnCheck}
                            iconStyle={{borderColor: "#777", marginRight: 8}}
                            disabled={!template.active}/>
                </div>
                <div className="row-container">
                  <Checkbox label="On"
                            checked={endsSelected === "on"}
                            id="on"
                            onCheck={(evt) => this.handleCheckTaskFrequencyEnds("on", evt)}
                            labelStyle={{color: "#787878", fontSize: 13, fontWeight: "400", fontFamily: "'Open Sans', sans-serif"}}
                            checkedIcon={elementCheck}
                            uncheckedIcon={elementUnCheck}
                            iconStyle={{borderColor: "#777", marginRight: 8}}
                            className="task-freq-on-check"
                            disabled={!template.active}/>
                  <DPKeyboardDatePicker
                    id={"onDate"}
                    name={"onDate"}
                    className="task-dueDate"
                    value={new Date(frequencyUntil)}
                    onChange={(date) => this.handleChangeDate('onDate', date)}
                    disabled={endsSelected !== "on"}
                  />
                </div>
                <div className="row-container">
                  <Checkbox label="After"
                            checked={endsSelected === "after"}
                            id="after"
                            onCheck={(evt) => this.handleCheckTaskFrequencyEnds("after", evt)}
                            labelStyle={{color: "#787878", fontSize: 13, fontWeight: "400", fontFamily: "'Open Sans', sans-serif"}}
                            checkedIcon={elementCheck}
                            uncheckedIcon={elementUnCheck}
                            iconStyle={{borderColor: "#777", marginRight: 8}}
                            className="task-freq-on-check-after"
                            disabled={!template.active}/>
                  <InputNumber idParent="freq-task-every"
                               id="numberAfter"
                               parentStyle="task-freq-parent-input-after"
                               value={frequencyCount}
                               dynamicNumberStyle="freq-task-input"
                               onChange={this.onChangeInputNumber}
                               readOnly={endsSelected !== "after"}
                  />
                  <label className="task-freq-label">Ocurrences</label>
                </div>
              </div>
            </div> : null
        }
        {
          (task.typeTask === TASK_TYPE.REPAIR && section[SECTIONS.INFO] ) &&
            <SubTask
                taskId={id}
                statusValue={this.state.statusValue}
                userAssigned={this.state.userAssigned}
                priorities={priorities}
                priorityValue={this.state.priorityValue}
                userComment={userComment}
                parentTask={{
                  ...task,
                  repairCost: this.state.costs,
                  comments: this.state.comments
                }}
                data={subTasks}
                openDeleteModal={openDeleteModal}
                saveTaskInfo={this.props.saveTaskInfo}
                updateSubTask={this.props.updateSubTask}
                deleteSubTask={this.props.deleteSubTask}
            />
        }

        <div className={isDisabled ? "task-comments-container-disabled" : "task-comments-container"}>
          <label className="comment-title">Comments</label>
          <div className="comments-parent">
            {
              comments.length > 0 ?
                comments.map((com, index) => {
                  return (
                    (com.userId === userComment.id) ?
                      <div key={"comment" + index} className="comment-item">
                        <div className="comment-right">
                          <label>{com.comment}</label>
                        </div>
                        <div className="icon-user-name">
                          {userComment.image ?
                            <img src={userComment.image}/> :
                            userComment.initials}
                        </div>
                      </div> :
                      <div key={"comment" + index} className="comment-item">
                        <div className="icon-user-name">
                          {this.getImageOrInitials(com.userId)}
                        </div>
                        <div className="comment-left">
                          <label>{com.comment}</label>
                        </div>
                      </div>
                  )
                }) : null
            }
          </div>
          <div className="new-comment-container">
            <input type="text" className="input-comment" value={newComment} placeholder="Type your comment..." onChange={this.onChangeNewComment}/>
            <ReactSVG src={pathServer.PATH_IMG + "icon/ic_send_24px.svg"}
                      className="icon-send-container" beforeInjection={ svg => svg.classList.add("icon-send")} onClick={this.addNewComment.bind(this)}/>
          </div>
        </div>
        { showPopover  ? <Popover anchorEl={anchorEl}
                                            child={childOptions}
                                            classPopover="popover-box-message"
                                            handleRequestClose={this.handleRequestClose}/> : null
        }
        {
          (isMobilView && showPopoverCar) ?
            <Popover anchorEl={anchorEl} classPopover="popover-car-search" child={renderFilter}
                     handleRequestClose={this.handleRequestClose} id="popoverBox"/>
            :
            showModalCarSearch ?
              <ModalCarSearch show={showModalCarSearch} cars={cars} makes={makes} models={models} locations={locations}
                              onHide={this.handleRequestClose} handleCarSelect={this.handleCarSelect}/>
              : null
        }
        {
          isCostAttached && <ModalConfirmDeactivate show={isCostAttached}
                                                    onSubmit={() => {}}
                                                    onHide={this.hideModal}
                                                    showButton={false}
                                                    isBodyCustom={isCostAttached}
                                                    bodyMessage={element}
                                                    message=""
                                                    iconQuestion={"icon/ic_warning.svg"}
                                                    classButtonDelete="btn-delete"
                                                    buttonLabelDelete={""}
                                                    viewMobile={false} />
        }
      </div>
      </div>
    )
  }
}

export default TaskDetail