/**
 * Created by johnny on 15/08/16
 */

import { assign, isEmpty } from 'lodash'
import { importKey, IS_LOGGED_IN_KEY, VIEW_SALE } from './Constants'
import { toUrlFormEncoded } from './newComponents/util/Utils'
import { getFiltersAsParams } from './Utils'

export let PortalEndpoints = {
  SEARCH_INVENTORY: 'api/inventory/search',
  UPLOAD_IMAGE: 'uploadImage',
  CAR_UPLOAD_IMAGE: 'api/inventory/car/uploadImage',
  CAR_UPLOAD_REPORT: 'api/inventory/car/uploadReport',
  CAR_IMAGE_LOAD: 'api/inventory/car/image',
  UPDATE_ORDER_IMAGE_CAR: 'api/inventory/car/updateOrderImage',
  UPDATE_ORDER_CAR: 'api/inventory/car/updateOrder',
  UPDATE_STATUS_FORM: 'api/form/status/',
  CAR_IMAGE_DELETE: 'api/inventory/car/',
  LOAD_CAR_BY_ID: 'api/inventory',
  CAR_SAVE: 'api/inventory/car',
  UPDATE_SHOW_CAR: 'api/inventory/car/show',
  EXIST_VIN_SALE: 'api/inventory/car/existSale/',
  CAR_HAS_REPAIR_SALE: 'api/inventory/car/carHasRepairOrSale/',
  EXIST_VIN_ACTIVE: 'api/inventory/car/exist/',
  UPDATE_STATUS_CAR: 'api/inventory/car/status/',
  DELETE_INVENTORY: 'api/inventory/car/',
  SEARCH_REPAIRS: 'api/car/',
  // PROCESS_REPAIRS: 'api/inventory/',
  DELETE_REPAIR: 'api/inventory/',
  // UPLOAD_CAR_INFO: 'api/inventory/car/uploadInfoCsv',
  PRICE_SAVE: 'api/inventory/',
  EXPORT_CAR_INVENTORY: 'api/generateCSV',
  LOAD_PRICE_BY_ID: 'api/inventory',
  CUSTOMER_SAVE: 'api/customer/customer',
  LOAD_CUSTOMER_BY_ID: 'api/customer',
  LOAD_CUSTOMERS: 'api/customers',
  LOAD_MESSAGES: 'api/messages',
  LOAD_RESPONSE_MESSAGE: 'api/responseMessage',
  UPDATE_STATE_RESPONSE_MESSAGE: 'api/responseMessage/updateState',
  UPDATE_MESSAGE: 'api/messages/update',
  DEACTIVATE_MESSAGE: 'api/messages/deactivate',
  UPDATE_STATE_MESSAGE: 'api/messages/updateState',
  LOAD_MESSAGE: 'api/message',
  DELETE_MESSAGE: 'api/message/deleteUndelete',
  DELETE_RESPONSE_MESSAGE: 'api/responseMessage/deleteUndelete',
  UPDATE_READ_MESSAGE: 'api/messages/updateStateRead/',
  COUNT_MESSAGES: 'api/countMessages/',
  LOAD_FINANCES: 'api/finances',
  LOAD_FINANCE_BY_ID: 'api/finance',
  UPDATE_FINANCE: 'api/finances/update',
  UPDATE_STATE_FINANCE: 'api/finances/updateState',
  SET_AS_MAIN_IMAGE: 'api/inventory/image',
  LOAD_USERS: 'api/users/load',
  UPDATE_STATUS_USER: 'api/user/',
  DELETE_USER: 'api/user/delete',
  USER_HAS_SALE_TASKS: 'api/users/userHasSaleOrTasks',
  SEND_USER_VERIFICATION_EMAIL: 'api/users/sendEmailVerification',
  LOAD_ACCOUNT: 'api/user/account',
  UPDATE_ACCOUNT: 'api/user/updateAccount',
  PROCESS_USER: 'processUser',
  REGISTER_USER: 'registerUser',
  LOGOUT: 'logout',
  SIGN_IN: 'signin',
  CHANGE_PASSWORD: 'changePassword',
  SEND_EMAIL_RECOVER_PASSWORD: 'recoverPassword',
  UPLOAD_PHOTO: 'api/upload/photo',
  DELETE_PHOTO: 'api/delete/photo',
  SEARCH_ROL: 'api/roles',
  DELETE_ROL: 'api/rol',
  SAVE_ROL: 'api/rol',
  FIND_ROLE: 'api/rol',
  LOAD_INVENTORY: 'api/inventory',
  SEARCH_APPLICANTS: 'api/applicant/search',
  APPLICANT_UPDATE: 'api/applicant/update',
  UPLOAD_CUSTOMERS: 'api/customer/upload',
  SAVE_MESSAGE: 'api/message',
  LOAD_MAKE_AND_MODEL: 'api/inventory/car/loadMakeAndModel',
  UPDATE_CAR_ATTRIBUTE_VALUE: 'api/inventory/carAttributeValue',
  UPLOAD_MAKE_AND_MODEL: 'api/setting/saveMakesAndModels',
  LOAD_LOCATIONS: 'api/locations',
  SAVE_LOCATION: 'api/location',
  DELETE_LOCATION: 'api/deleteLocation',
  LOAD_FORM_BY_CODE: 'api/forms',
  PROCESS_FORMS: 'api/processForm',
  LOAD_FINANCE_FORM_BY_CODE: 'api/financeForm',
  LOAD_ATTRIBUTES_BY_ENTITY: 'api/form/attributes',
  SAVE_FORM: 'api/form',
  SEARCH_FORM: 'api/forms',
  SAVE_ATTRIBUTE: 'api/attribute',
  FIND_ATTRIBUTE: 'api/attribute',
  SEARCH_ATTRIBUTES: 'api/attributes',
  DElETE_ATTRIBUTE: 'api/attribute',
  UPDATE_STATUS_ATTRIBUTE: 'api/attribute/',
  DElETE_CUSTOMER: 'api/customer',
  TEMPLATE_UPLOAD_IMAGE: 'api/template/uploadImage/',
  SET_AS_MAIN_TEMPLATE: 'api/dealer/template/',
  REPLY_EMAIL: 'api/messages/reply',
  LOAD_ATTRIBUTE_VALUES_BY_ATTRIBUTE_CODE: 'api/attribute/',
  SAVE_TEMPLATE_AND_DEALER_MEDIA: 'api/template/dealerMedia/',
  UPDATE_TESTIMONIAL_STATUS: 'api/dealer/testimonial/',
  UPDATE_DEALER_CONFIGURATION_VALUE: 'api/dealer/configuration/updateValue/',
  UPDATE_DEALER_CONFIGURATION_VALUES: 'api/dealer/configuration/updateValues',
  DELETE_DEALER_CONFIGURATION: 'api/dealer/configuration/delete/',
  LOAD_DEALER_CONFIGURATION_BY_CODE: 'api/dealer/configuration/loadByCode',
  LOAD_DEALER_CONFIGURATION_BY_CODES: 'api/dealer/configuration/loadByCodes/',
  LOAD_TASKS_BY_FILTERS: 'api/tasks',
  DELETE_TASK: 'api/tasks/',
  UPDATE_TASK_STATUS: 'api/task/',
  UPDATE_REORDER_TASK: 'api/tasks',
  ADD_COST_TASK: 'api/repair',
  DELETE_COST_TASK: 'api/repair',
  BOARD_API: 'api/board',
  SAVE_REPAIR_DOCUMENT: 'api/task/uploadFiles/',
  //Testimonials APIs
  UPDATE_TESTIMONIALS: 'api/dealer/testimonials/update',
  LOAD_TESTIMONIALS: 'api/dealer/testimonials',
  REPLY_TESTIMONIAL: 'api/testimonials/replyTestimonial/',
  EDIT_TESTIMONIAL_COMMENT: 'api/testimonials/editComment/',
  VERIFY_PAGE_SUBSCRIPTION: 'api/testimonials/verifyWebhookSubscription',
  SUBSCRIBE_PAGE_TO_WEBHOOK: 'api/testimonials/subscribePageToWebhook',
  VALIDATE_ACCESS: '/api/marketing/validateAccess',
  UPDATE_GOOGLE_TESTIMONIALS: 'api/testimonials/googleReviews',

  PROCESS_TASK: 'api/task/',
  UPDATE_ACTIVE_TASK: 'api/task/',
  SAVE_TASK_AND_REPAIR: 'api/task/saveTaskAndRepair',
  ADD_RECEIPTS_REPAIR: 'api/repair/receipt/',
  PROCESS_LEAD: 'api/leads/',
  LOAD_SEARCH_CUSTOMERS: 'api/customers',
  DISABLE_CUSTOMER: 'api/customers',
  SAVE_CUSTOMER_AND_IMAGE: 'api/customers',
  UPDATE_CUSTOMER: 'api/customers',
  LOAD_SALE_BY_CUSTOMER_ID: 'api/customer/',
  UPDATE_LOCATION_STATUS: 'api/location/updateStatus/',
  VEHICLES_DATA_URL: 'https://vpic.nhtsa.dot.gov/api/vehicles',
  VIN_NUMBER_SEARCH_URL: '/DecodeVinValues/{vinNumber}?format=json',
  LOAD_ACTIVE_FILE_TEMPLATE: 'api/fileTemplate',
  SAVE_DEALER_CONFIGURATION: 'api/dealer/configuration/save',
  STATIC_FILE: '/staticFiles',
  LOAD_ALL_ROLES: 'api/roles ',
  LOAD_ALL_DEALERS: 'api/dealers',
  UPDATE_STATUS_DEALER: 'api/dealers/updateStatus/',
  LOAD_SUPERUSERS: 'api/superusers',
  UPDATE_STATUS_SUPERUSERS: 'api/superusers/',
  UPDATE_SUPERUSER: 'api/superusers/',
  SAVE_DEALER: 'api/dealers',
  LOAD_DEALER: 'api/dealer',
  DEALER_SETUP: 'api/dealer/setup',
  VERIFY_SUBDOMAIM: 'api/dealers/verifySubdomain',
  LOAD_PERMISSIONS: 'api/permissions',
  LOAD_ALL_USERS_ACTIVE: 'api/users/loadAll',
  FINANCE_SAVE_UPDATE: 'api/customers/',
  DEALER_SALE_STATIC_FILES: 'api/dealerSaleStaticFiles',  // Used on GET, DELETE, POST and PUT
  DEALER_SALE_STATIC_FILES_LIST: 'api/dealerSaleStaticFileList', // Used on UPDATE
  UPDATE_REORDER_CHECKLIST: 'api/dealerChecklist',
  SAVE_CHECKLIST: 'api/dealerChecklist',
  UPDATE_CHECKLIST: 'api/dealerChecklist/',
  DELETE_CHECKLIST: 'api/dealerChecklist/',
  LOAD_CHECKLIST: 'api/dealerChecklist',
  LOAD_ATTRIBUTE_VALUES_BY_ATTRIBUTE_CODES: 'api/attributes/attributeValues',
  UPDATE_REORDER_ACCESSORIES: 'api/accessory',
  SAVE_ACCESSORY: 'api/accessory',
  UPDATE_ACCESSORY: 'api/accessory/',
  DELETE_ACCESSORY: 'api/accessory/',
  LOAD_ACCESSORIES: 'api/accessory',
  GET_VIN: 'api/inventory/',
  LOAD_FILE_TEMPLATE: 'api/fileTemplate/',
  SAVE_FILE_TEMPLATE: 'api/fileTemplate',
  UPDATE_FILE_TEMPLATE: 'api/fileTemplate/',
  UPDATE_TEMPORARY_CAR: 'api/inventory/',
  UPDATE_IMAGE_CUSTOMER: 'api/customers/',
  SAVE_CAR_DOCUMENT: 'api/inventory/car/uploadDocuments/',
  DELETE_APPLICANT: 'api/finance/deleteApplicant',
  CHANGE_PASSWORD_ACCOUNT: 'api/changePassword',
  IMPORT_CSV_FILE_CAR: 'api/inventory/car/uploadInfoCsv',
  GET_VISUALIZATION: 'api/report/',
  GET_DEALER_STATE_TAX: 'api/sales/configurations/stateTax',
  INSERT_UPDATE_DEALER_STATE_TAX: 'api/dealer/configuration/stateTax',
  SAVE_POST: 'api/socialNetwork',
  GET_POSTID: 'api/inventory/',
  SALE_API: 'api/sale/',
  SAVE_NEW_FINANCE_APPLICATION: 'api/finances/save',
  EXPORT_CUSTOMERS: 'api/generateCSVCustomerFile',
  IMPORT_CSV_FILE_CUSTOMER: 'api/customers/uploadInfoCsv',
  FORM_API: 'api/form/',
  SOCIAL_NETWORK_LOGIN_HISTORY_API: 'api/snlHistory/',
  SALES_API: 'api/sales/',
  //AuthorizeNet apis
  INVOICE_BILLING_API: 'api/billing/',
  GET_ACCEPT_SUIT: 'api/payment/suite',
  CHARGE_TRANSACTION: 'api/payment/charge',
  ADD_PAYMENT_METHOD: 'api/payment/method/save',
  UPDATE_PAYMENT_METHOD: 'api/payment/method/update',
  DELETE_PAYMENT_METHOD: 'api/payment/method',
  GET_PAYMENT_METHODS: 'api/payment/methods/dealer',
  //Braintree Apis
  CLIENT_TOKEN: 'api/payment/clientToken',
  PROCESS_ORDER: 'api/payment/processOrder',

  INITIALIZE_PAYMENT_PROCESSOR: 'api/payment/initialize',

  TEMPLATE_API: 'api/template',
  VERIFY_USER: 'api/user/verifyUser',
  LOAD_MAKE_AND_MODEL_ALL: 'api/inventory/car/loadMakeAndModelAll',
  GENERATE_CSV_URL_FOR_FBMP: 'api/generateCSVUrlForFMBP',

  LOAD_SEOINFO: 'api/seoinfo',
  SAVE_SEOINFO: 'api/seoinfo',
  SAVE_SEOINFOLIST: 'api/seoinfo/list',
  SEO_API: 'api/seoinfo',
  CREDENTIAL_API: 'api/credential',

  CREATE_INVOICE: 'api/billing/invoice',

  FEATURE_FLAGS: 'api/flags',

  WEBHOOK_DATA: 'api/webhookData',

  ORDER_API: 'api/order/',
  INVENTORY_ITEM_API: 'api/inventoryItem',

  LOAD_INVOICES: 'api/payment/loadInvoices',

  PAYMENT_API: 'api/payment/',

  UPDATE_KEEP_LOGGED_IN: 'updateKeepLoggedIn',

  //Google -> sign in
  GOOGLE_AUTHORIZATION: 'googleAuthorizationCode ',
  GOOGLE_API: 'api/google/',

  REVIEW_API: 'api/reviews/'
};

export let MarketingEndpoints = {
  SAVE_SUBSCRIBER: 'api/marketing/subscriber',
  UNSUBSCRIBE: 'api/marketing/unsubscribe',
  GET_DEMO: 'api/marketing/sendDemoEmail',
  SEND_CONTACT_INFO_EMAIL: 'api/marketing/sendContactInfoEmail',
}

export function getServerUrl() {
  if (window.baseHref) {
    return window.baseHref
  }
  return ''
  // return 'https://localhost:8080/'
}

function doGet(url, body, props) {
  props = props || {}
  return fetch(
    url,
    assign({
      method: 'get',
      body: body,
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }, props)
  )
    .then(checkStatus)
}

function doPost(url, body, props) {
  props = props || {}
  //body = body || '';
  return fetch(
    url,
    assign({
      method: 'post',
      body: body,
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }, props)
  )
    .then(checkStatus)

}

function doDelete(url, body, props) {
  props = props || {}
  return fetch(
    url,
    assign({
      method: 'delete',
      body: body,
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }, props)
  )
    .then(checkStatus)
}

export function getCarSpecsByVin(vin) {
  /*Application: DealerPro
   Key: qs4pu33cnafcwtamghqtpa5u
   Secret: bdYug34YgBVKbsdK7Fws3s6d*/
  const url = `https://api.edmunds.com/api/vehicle/v2/vins/${vin}?fmt=json&api_key=qs4pu33cnafcwtamghqtpa5u`
  return doGet(url)
}

function doPut(url, body, props) {
  props = props || {}
  //body = body || '';
  return fetch(
    url,
    assign({
      method: 'put',
      body: body,
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }, props)
  )
    .then(checkStatus)
}

function checkStatus(response) {
  return response.json().then((data) => {
    if (data.status === 401) {
      return
    }
    let newResponse = { ...data, status: response.status, ok: response.ok }
    if (response.status >= 200 && response.status < 300) {
      return newResponse
    } else {
      throw newResponse
    }
  })
}

export function searchInventoryApi(data, from) {
  let url = ''
  if (from && from === VIEW_SALE)
    url = `?fromView=${from}`

  return doPost(`${getServerUrl()}${PortalEndpoints.SEARCH_INVENTORY}${url}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function exportCarInventory(data) {
  const params = getFiltersAsParams(data)
  return `${getServerUrl()}${PortalEndpoints.EXPORT_CAR_INVENTORY}${params}`
}
//todo not in use
export function uploadImage(customerId, userId, file) {
  let data = new FormData()
  data.append('fileName', file)
  return doPost(getServerUrl() + PortalEndpoints.UPLOAD_IMAGE + '/' + customerId + '/' + userId, data, { headers: {} })
}

export function updateOrderImage(carImages) {
  return doPost(getServerUrl() + PortalEndpoints.UPDATE_ORDER_IMAGE_CAR, JSON.stringify(carImages), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateOrderCar(cars) {
  return doPost(getServerUrl() + PortalEndpoints.UPDATE_ORDER_CAR, JSON.stringify(cars), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function deleteCarImages(carIdImages, vin) {
  return doPost(`${getServerUrl() + PortalEndpoints.CAR_IMAGE_DELETE}${vin}/image/delete`, JSON.stringify(carIdImages), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

//todo: we need this?
export function loadCarById(id) {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_CAR_BY_ID}/${id}`)
}

export function loadCarByVin(vin) {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_CAR_BY_ID}/${vin}/car`)
}

export function loadCarImageByIdOld(id) {
  return doPost(getServerUrl() + PortalEndpoints.LOAD_CAR_BY_ID + '/' + id + '/carImages', '', { headers: {} })
}

//todo we need this?
export function loadCarImageById(id) {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_CAR_BY_ID}/${id}/carImages`)
}

export function saveCar(car) {
  return doPost(getServerUrl() + PortalEndpoints.CAR_SAVE, JSON.stringify(car), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateShowCar(carUpdate) {
  return doPut(`${getServerUrl() + PortalEndpoints.UPDATE_SHOW_CAR}`, JSON.stringify(carUpdate), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function validateExistVinToSale(vin) {
  return doGet(`${getServerUrl() + PortalEndpoints.EXIST_VIN_SALE}${vin}`)
}

export function carHasRepairOrSale(carId) {
  return doGet(`${getServerUrl() + PortalEndpoints.CAR_HAS_REPAIR_SALE}${carId}`)
}

export function validateExistVinActive(vin, carId) {
  return doGet(`${getServerUrl() + PortalEndpoints.EXIST_VIN_ACTIVE}${vin}/${carId}`)
}

export function updateActiveCar(carId) {
  return doPut(`${getServerUrl() + PortalEndpoints.UPDATE_STATUS_CAR}${carId}`, '', { headers: {} })
}

export function uploadCarImage(carId, files, indexMainImage) {
  let data = new FormData()
  let orders = []

  if (files && files.length > 0) {
    let fileNames = []
    files.forEach((file) => {
      fileNames.push(file.name)
      orders.push(file.order)
      data.append(file.name, file)
    })
    data.append('fileNames', fileNames)
    data.append('orders', orders)
    data.append('indexMainImage', indexMainImage)
  }
  return doPost(getServerUrl() + PortalEndpoints.CAR_UPLOAD_IMAGE + '/' + carId, data, { headers: {} })
}

export function deleteInventoryApi(carId, password) {
  return doDelete(`${getServerUrl() + PortalEndpoints.DELETE_INVENTORY}${carId}/${password}`, '', { headers: {} })
}

//todo: not in use
export function uploadCarReport(carId, files) {
  let data = new FormData()

  if (files && files.length > 0) {
    let fileNames = []
    files.map((file) => {
      fileNames.push(file.name)
      data.append(file.name, file)
    })
    data.append('fileNames', fileNames)
  }
  return doPost(getServerUrl() + PortalEndpoints.CAR_UPLOAD_REPORT + '/' + carId, data, { headers: {} })
}

//todo: not in use
export function searchRepairsApi(vin) {
  return doGet(`${getServerUrl() + PortalEndpoints.SEARCH_REPAIRS}${vin}/repairs`)
}

// export function processRepairApi(body,vin){
//     return doPost(getServerUrl() + PortalEndpoints.PROCESS_REPAIRS+vin+'/processRepair',JSON.stringify(body),{headers: {
//         'Content-Type': 'application/json'
//     }})
// }

//todo: not in use
// export function deleteRepairApi(body) {
//   return doPost(getServerUrl() + PortalEndpoints.DELETE_REPAIR + body.id + '/deleteRepair', '', { headers: {} })
// }

//todo: not in use
// export function uploadCarInfo(cars) {
//   return doPost(getServerUrl() + PortalEndpoints.UPLOAD_CAR_INFO, JSON.stringify(cars), {
//     headers: {
//       'Content-Type': 'application/json'
//     }
//   })
// }

export function loadMakeAndModelApi() {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_MAKE_AND_MODEL}`)
}

export function loadMakeAndModelAllApi() {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_MAKE_AND_MODEL_ALL}`)
}

//todo: not in use
export function savePrice(price, vin) {
  return doPost(getServerUrl() + PortalEndpoints.PRICE_SAVE + vin + '/price', JSON.stringify(price), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
//todo: not in use
export function loadPriceByVin(vin) {
  return doPost(getServerUrl() + PortalEndpoints.LOAD_PRICE_BY_ID + '/' + vin + '/priceLoad', '', { headers: {} })
}

export function saveCustomer(customer) {
  return doPost(`${getServerUrl() + PortalEndpoints.CUSTOMER_SAVE}`, JSON.stringify(customer), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function loadCustomerById(customerId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_CUSTOMER_BY_ID}/${customerId}`)
}

export function loadMessagesApi() {
  return doGet(getServerUrl() + PortalEndpoints.LOAD_MESSAGES)
}

export function loadResponseMessageApi() {
  return doGet(getServerUrl() + PortalEndpoints.LOAD_RESPONSE_MESSAGE)
}

export function updateMessage(message) {
  return doPut(`${getServerUrl() + PortalEndpoints.UPDATE_MESSAGE}`, JSON.stringify(message), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateStateResponseMessage(responseMessage) {
  return doPost(`${getServerUrl() + PortalEndpoints.UPDATE_STATE_RESPONSE_MESSAGE}`, JSON.stringify(responseMessage), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function deactivateMessage(message) {
  return doPost(`${getServerUrl() + PortalEndpoints.DEACTIVATE_MESSAGE}`, JSON.stringify(message), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateStateMessages(messages) {
  return doPost(`${getServerUrl() + PortalEndpoints.UPDATE_STATE_MESSAGE}`, JSON.stringify(messages), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateStateFinances(finances) {
  return doPost(`${getServerUrl() + PortalEndpoints.UPDATE_STATE_FINANCE}`, JSON.stringify(finances), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function loadMessage(messageId, read) {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_MESSAGE}/${messageId}/${read}`)
}

export function deleteUndeleteMessageApi(deleteUndelete) {
  return doPost(`${getServerUrl()}${PortalEndpoints.DELETE_MESSAGE}`, JSON.stringify(deleteUndelete), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function deleteUndeleteResponseMessageApi(deleteUndelete) {
  return doPost(`${getServerUrl()}${PortalEndpoints.DELETE_RESPONSE_MESSAGE}`, JSON.stringify(deleteUndelete), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateStateRead(messageId, read) {
  return doPut(`${getServerUrl() + PortalEndpoints.UPDATE_READ_MESSAGE + messageId + '/' + read}`, '', { headers: {} })
}

export function updateStatusForm(formId, newStatus) {
  return doPut(`${getServerUrl() + PortalEndpoints.UPDATE_STATUS_FORM + formId + '/' + newStatus}`, '', { headers: {} })
}

export function countMessagesApi(read) {
  return doGet(`${getServerUrl()}${PortalEndpoints.COUNT_MESSAGES + read}`)
}

export function replyMessagesApi(message, files) {
  let data = new FormData()
  if (files && files.length > 0) {
    let fileNames = []
    files.forEach((file) => {
      fileNames.push(file.name)
      data.append(file.name, file.file)
    })
    data.append('fileNames', fileNames)
  }
  data.append('message', JSON.stringify(message))
  return doPost(`${getServerUrl() + PortalEndpoints.REPLY_EMAIL}`, data, { headers: {} })
}

export function loadFinancesApi(customerId) {
  const params = toUrlFormEncoded({ customerId })
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_FINANCES}${params}`)
}

export function loadFinanceById(financeId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_FINANCE_BY_ID}/${financeId}`)
}

export function updateFinance(finance) {
  return doPost(`${getServerUrl() + PortalEndpoints.UPDATE_FINANCE}`, JSON.stringify(finance), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function setAsMainImageApi(carId, imageId) {
  return doPost(`${getServerUrl()}${PortalEndpoints.SET_AS_MAIN_IMAGE}/${carId}/${imageId}`)
}

export function loadUsersApi(from, size, keyword, showInactives, rolId) {
  const params = toUrlFormEncoded({ keyword, from, size, showInactives, rolId })
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_USERS}${params}`)
}

export function sendEmailVerification() {
  return doGet(`${getServerUrl()}${PortalEndpoints.SEND_USER_VERIFICATION_EMAIL}`)
}

export function loadAccountApi() {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_ACCOUNT}`)
}

export function addUserApi(body) {
  return doPost(getServerUrl() + PortalEndpoints.PROCESS_USER, JSON.stringify(body), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function addRegisterUser(user, photo) {
  let data = new FormData()
  data.append('user', JSON.stringify(user))
  if (photo) {
    data.append(photo.name, photo)
  }
  return doPost(`${getServerUrl() + PortalEndpoints.REGISTER_USER}`, data, { headers: {} })
}

export function updateAccountApi(user, photo) {
  let data = new FormData()
  data.append('user', JSON.stringify(user))
  if (photo) {
    data.append(photo.name, photo)
  }
  return doPost(`${getServerUrl() + PortalEndpoints.UPDATE_ACCOUNT}`, data, { headers: {} })
}

export function addReceiptRepair(receipts, repairId) {
  let data = new FormData()
  // todo save multiple files
  let receiptsToInsert = []
  if (receipts && receipts.length > 0) {
    receipts.forEach((doc, index) => {
      if (!doc.id) {
        data.append(doc.name, doc.file)
        receiptsToInsert.push({ name: doc.name })
      }
    })

    data.append('receipts', JSON.stringify(receiptsToInsert))
  }
  // if(receiptsIds && receiptsIds.length > 0)
  //   data.append('receiptsIds',JSON.stringify(receiptsIds));
  // if(photo){
  //   data.append(photo.name, photo);
  // }
  return doPost(`${getServerUrl() + PortalEndpoints.ADD_RECEIPTS_REPAIR + repairId}`, data, { headers: {} })
}

export function deleteReceiptRepair(id) {
  return doDelete(`${getServerUrl() + PortalEndpoints.ADD_RECEIPTS_REPAIR + id}`)
}


export function signInApi(body) {
  return doPost(getServerUrl() + PortalEndpoints.SIGN_IN, JSON.stringify(body), {
    headers: { 'Content-Type': 'application/json' }
  })
}

export function logoutApi() {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOGOUT}`)
}

export function changePasswordApi(body) {
  return doPost(getServerUrl() + PortalEndpoints.CHANGE_PASSWORD, JSON.stringify(body), {
    headers: { 'Content-Type': 'application/json' }
  })
}

export function sendMailRecoverPasswordApi(email) {
  return doGet(getServerUrl() + PortalEndpoints.SEND_EMAIL_RECOVER_PASSWORD + '/' + email)
}
//todo: not in use
export function uploadPhoto(id, body) {
  let type = body.type.split('/').pop()
  return doPost(`${getServerUrl()}${PortalEndpoints.UPLOAD_PHOTO}/${id}/${type}`, body, {
    headers: { 'Content-Type': 'text/plain' }
  })
}
//todo: not in use
export function deletePhoto(id) {
  return doDelete(`${getServerUrl()}${PortalEndpoints.DELETE_PHOTO}/${id}`, '', { headers: {} })
}

export function deleteUserApi(id) {
  return doDelete(`${getServerUrl()}${PortalEndpoints.DELETE_USER}/${id}`, '', {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function userHasSaleorTasks(id) {
  return doGet(`${getServerUrl()}${PortalEndpoints.USER_HAS_SALE_TASKS}/${id}`)
}

export function saveMessage(data) {
  return doPost(getServerUrl() + PortalEndpoints.SAVE_MESSAGE, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

//todo: not in use
export function uploadCustomers(data) {
  return doPost(getServerUrl() + PortalEndpoints.UPLOAD_CUSTOMERS, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateApplicant(data) {
  return doPost(getServerUrl() + PortalEndpoints.APPLICANT_UPDATE, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateCarAttributeValue(carUpdate) {
  return doPut(`${getServerUrl() + PortalEndpoints.UPDATE_CAR_ATTRIBUTE_VALUE}`, JSON.stringify(carUpdate), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function loadEdmundsMakes() {
  return doGet('https://api.edmunds.com/api/vehicle/v2/makes?fmt=json&api_key=qs4pu33cnafcwtamghqtpa5u')
}

export function saveMakesAndModels(data) {
  return doPost(getServerUrl() + PortalEndpoints.UPLOAD_MAKE_AND_MODEL, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function loadLocationsApi(status = null) {
  const params = toUrlFormEncoded({ status })
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_LOCATIONS}${params}`)
}

export function saveLocationApi(location) {
  return doPost(`${getServerUrl() + PortalEndpoints.SAVE_LOCATION}`, JSON.stringify(location), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function loadSEOInfoApi(status = null) {
  const params = toUrlFormEncoded({ status })
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_SEOINFO}${params}`)
}

export function saveSEOInfoAPI(seoInfo) {
  return doPost(`${getServerUrl() + PortalEndpoints.SAVE_SEOINFO}`, JSON.stringify(seoInfo), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function saveSEOInfoListAPI(seoInfoList) {
  return doPost(`${getServerUrl() + PortalEndpoints.SAVE_SEOINFOLIST}`, JSON.stringify(seoInfoList), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function deleteLocationApi(id) {
  return doPost(`${getServerUrl()}${PortalEndpoints.DELETE_LOCATION}/${id}`, '', { headers: {} })
}

export function loadFormByCode(formCode, objectId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_FORM_BY_CODE}/${formCode}/${objectId}`)
}

export function processFormApi(data) {
  return doPost(`${getServerUrl() + PortalEndpoints.PROCESS_FORMS}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function loadFinanceFormByCode(formId, objectId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_FINANCE_FORM_BY_CODE}/${formId}/${objectId}`)
}


export function saveFormApi(form, action) {
  return doPost(`${getServerUrl() + PortalEndpoints.FORM_API}?action=${action}`, JSON.stringify(form), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
//todo: not in use
export function cloneFormApi(form) {
  return doPut(`${getServerUrl() + PortalEndpoints.FORM_API}`, JSON.stringify(form), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function loadAttributesByEntity(entityType) {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_ATTRIBUTES_BY_ENTITY}/${entityType}`)
}

//todo: not in use
export function deleteFormApi(id) {
  return doDelete(`${getServerUrl()}${PortalEndpoints.FORM_API}${id}`, '', { headers: {} })
}

export function loadFormByIdApi(id) {
  return doGet(`${getServerUrl()}${PortalEndpoints.FORM_API}${id}`)
}

export function searchFormApi(keyword) {
  return doGet(`${getServerUrl()}${PortalEndpoints.SEARCH_FORM}?keyword=${keyword}`)
}

export function saveAttributeApi(attribute, action) {
  const params = toUrlFormEncoded({ action })
  return doPost(`${getServerUrl()}${PortalEndpoints.SAVE_ATTRIBUTE}/${params}`, JSON.stringify(attribute), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function findAttributeApi(id) {
  return doGet(`${getServerUrl()}${PortalEndpoints.FIND_ATTRIBUTE}/${id}`)
}

export function searchAttributes(values) {
  return doGet(`${getServerUrl()}${PortalEndpoints.SEARCH_ATTRIBUTES}?search=${values[0]}&entityType=${values[1]}`)
}

export function deleteAttributeApi(id) {
  return doDelete(`${getServerUrl()}${PortalEndpoints.DElETE_ATTRIBUTE}/${id}`, '', { headers: {} })
}

export function cloneAttributeApi(attribute) {
  return doPut(`${getServerUrl() + PortalEndpoints.SAVE_ATTRIBUTE}/`, JSON.stringify(attribute), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateStatusAttributeApi(attributeId, newStatus) {
  return doPut(`${getServerUrl() + PortalEndpoints.UPDATE_STATUS_ATTRIBUTE + attributeId + '/status/' + newStatus}`, '', { headers: {} })
}

//todo: not in use
export function deleteCustomerApi(id) {
  return doDelete(`${getServerUrl()}${PortalEndpoints.DElETE_CUSTOMER}/${id}`, '', { headers: {} })
}

export function searchRolApi(keyword) {
  const params = toUrlFormEncoded({ keyword })
  return doGet(`${getServerUrl()}${PortalEndpoints.SEARCH_ROL}${params}`)
}


export function deleteRolApi(id) {
  return doDelete(`${getServerUrl()}${PortalEndpoints.DELETE_ROL}/${id}`, '', { headers: {} })
}

export function saveRoleApi(form) {
  return doPost(`${getServerUrl()}${PortalEndpoints.SAVE_ROL}`, JSON.stringify(form), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function cloneRoleApi(form) {
  return doPut(`${getServerUrl() + PortalEndpoints.SAVE_ROL}/`, JSON.stringify(form), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function findRoleApi(id) {
  return doGet(`${getServerUrl()}${PortalEndpoints.FIND_ROLE}/${id}`)
}

export function saveTemplateApi(template) {
  return doPost(`${getServerUrl()}${PortalEndpoints.TEMPLATE_API}`, JSON.stringify(template), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function searchTemplateApi(keyword, from, size) {
  const params = toUrlFormEncoded({ keyword, from, size })
  return doGet(`${getServerUrl()}${PortalEndpoints.TEMPLATE_API}s${params}`)
}

export function findTemplateApi(id) {
  return doGet(`${getServerUrl()}${PortalEndpoints.TEMPLATE_API}/${id}`)
}

export function uploadTemplateImage(templateId, files, fields) {
  let data = new FormData()

  if (files && files.length > 0) {
    files.forEach((file, index) => {
      data.append(file.name, file, fields[index])
    })
  }
  return doPost(`${getServerUrl() + PortalEndpoints.TEMPLATE_UPLOAD_IMAGE}${templateId}`, data, { headers: {} })
}

export function setAsMainTemplate(templateId) {
  return doPut(`${getServerUrl() + PortalEndpoints.SET_AS_MAIN_TEMPLATE}${templateId}`, '', { headers: {} })
}

export function loadAttributeValuesByAttributeCode(code) {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_ATTRIBUTE_VALUES_BY_ATTRIBUTE_CODE}${code}/attributeValues`)
}

export function saveTemplateAndDealerMedia(body) {
  let data = new FormData()
  let orders = []
  const {
    templateId,
    content,
    files,
    fields,
    filesToDealerMedia,
    dealerMedias,
    dealerMediasToDelete,
    dealerMediasUpdate
  } = body

  if (files && files.length > 0) {
    files.forEach((file, index) => {
      data.append(file.name, file, fields[index])
    })
  }

  data.append('content', content)

  if (filesToDealerMedia && filesToDealerMedia.length > 0) {
    filesToDealerMedia.forEach(file => {
      orders.push(file.order)
      data.append(file.name, file, 'dealerMedia')
    })

    data.append('orders', JSON.stringify(orders))
  }

  if (dealerMedias)
    data.append('dealerMedias', dealerMedias)

  if (dealerMediasToDelete)
    data.append('dealerMediasToDelete', dealerMediasToDelete)

  if (dealerMediasUpdate)
    data.append('dealerMediasUpdate', dealerMediasUpdate)

  return doPost(`${getServerUrl()}${PortalEndpoints.SAVE_TEMPLATE_AND_DEALER_MEDIA}${templateId}`, data, { headers: {} })
}

export function loadTemplatesMain() {
  return doGet(`${getServerUrl()}${PortalEndpoints.TEMPLATE_API}s/main`)
}
//todo the Component[AccountConfirmation.jsx] that uses this API is not in use.
export function verifyUser(userId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.VERIFY_USER}/${userId}`)
}

export function updateTemplateStatus(id, active) {
  const params = toUrlFormEncoded({ active })
  return doPut(`${getServerUrl()}${PortalEndpoints.TEMPLATE_API}/${id}/status${params}`, '', { headers: {} })
}

export function updateTestimonialStatus(id, status) {
  return doPut(`${getServerUrl()}${PortalEndpoints.UPDATE_TESTIMONIAL_STATUS}${id}/${status}`, '', { headers: {} })
}

export function loadDealerConfigurationByCode(code) {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_DEALER_CONFIGURATION_BY_CODE}?code=${code}`)
}

export function loadDealerConfigurationByCodes(codes) {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_DEALER_CONFIGURATION_BY_CODES}?codes=${codes}`)
}

export function updateDealerConfigurationValues(body) {
  return doPut(`${getServerUrl()}${PortalEndpoints.UPDATE_DEALER_CONFIGURATION_VALUES}`, JSON.stringify(body), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function loadTasksByFilters(priority, users, type, taskFilters) {
  let url = ''

  if (priority)
    url = `?priority=${priority}`

  if (users && users !== '') {
    if (url !== '')
      url += `&users=${users}`
    else
      url = `?users=${users}`
  }

  if (type && type !== '') {
    if (url !== '')
      url += `&taskType=${type}`
    else
      url = `?taskType=${type}`
  }

  if (taskFilters && taskFilters !== '' && taskFilters.length >= 1) {
    if (url !== '')
      url += `&taskFilters=${taskFilters}`
    else
      url = `?taskFilters=${taskFilters}`
  }
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_TASKS_BY_FILTERS}${url}`)
}

export function loadBoards() {
  return doGet(`${getServerUrl()}${PortalEndpoints.BOARD_API}`)
}

export function uploadRepairDoc(body) {
  let data = new FormData()
  const { taskId, documents, documentIds } = body

  let documentsToInsert = []
  if (documents && documents.length > 0) {
    documents.forEach((doc, index) => {
      data.append(doc.name, doc.file)
      documentsToInsert.push({ name: doc.name, weight: doc.weight.toString() })
    })

    data.append('documents', JSON.stringify(documentsToInsert))
  }

  if (documentIds.length > 0)
    data.append('documentIds', JSON.stringify(documentIds))

  return doPost(`${getServerUrl()}${PortalEndpoints.SAVE_REPAIR_DOCUMENT}${taskId}`, data, { headers: {} })
}

export function uploadCarDocument(body) {
  let data = new FormData()
  const { carId, documents, documentIds } = body

  let documentsToInsert = []
  if (documents && documents.length > 0) {
    documents.forEach((doc, index) => {
      data.append(doc.name, doc.file)
      documentsToInsert.push({ name: doc.name, documentType: doc.documentType })
    })

    data.append('documents', JSON.stringify(documentsToInsert))
  }

  if (documentIds.length > 0)
    data.append('documentIds', JSON.stringify(documentIds))

  return doPost(`${getServerUrl()}${PortalEndpoints.SAVE_CAR_DOCUMENT}${carId}`, data, { headers: {} })
}

export function processTaskApi(data) {
  return doPost(`${getServerUrl() + PortalEndpoints.PROCESS_TASK}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function saveTaskCost(data) {
  return doPost(`${getServerUrl() + PortalEndpoints.ADD_COST_TASK}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function deleteTaskCostApi(repairId, from) {
  const params = toUrlFormEncoded({ from })
  return doDelete(`${getServerUrl() + PortalEndpoints.ADD_COST_TASK}/${repairId}${params}`)
}

export function saveTaskAndRepair(data) {
  return doPost(`${getServerUrl() + PortalEndpoints.SAVE_TASK_AND_REPAIR}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function loadTasksById(id) {
  return doGet(`${getServerUrl()}${PortalEndpoints.PROCESS_TASK}${id}`)
}

export function updateTaskActiveApi(id) {
  return doPut(`${getServerUrl() + PortalEndpoints.UPDATE_ACTIVE_TASK + id + '/active'}`, '', { headers: {} })
}

export function deleteTaskApi(id) {
  return doDelete(`${getServerUrl() + PortalEndpoints.DELETE_TASK + id}`, '', { headers: {} })
}

export function promoteLead(id, customerId, create) {
  let url = ''

  if (customerId !== null && customerId) {
    url += `?customerId=${customerId}`
  }
  if (create && customerId === null) {
    url += `?create=${create}`
  } else if (create && customerId !== null && customerId) {
    url += `&create=${create}`
  }

  return doPost(`${getServerUrl() + PortalEndpoints.PROCESS_LEAD + id + '/promote'}${url}`, '', { headers: {} })
}

export function disableCustomerApi(id) {
  return doDelete(`${getServerUrl()}${PortalEndpoints.DISABLE_CUSTOMER}/${id}`, '', { headers: {} })
}

export function loadCustomersApi(keyword, from, size, sourceId = 'dealer') {
  let url = ''

  if (!isEmpty(keyword))
    url = `?keyword=${encodeURIComponent(keyword)}`

  if (!isEmpty(sourceId))
    if (isEmpty(url))
      url = `?sourceId=${sourceId}`
    else
      url += `&sourceId=${sourceId}`

  if (from && from !== null) {
    if (isEmpty(url))
      url = `?from=${from}`
    else
      url += `&from=${from}`
  }

  if (size && size !== null && size !== 0) {
    if (isEmpty(url))
      url = `?size=${size}`
    else
      url += `&size=${size}`
  }
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_CUSTOMERS}${url}`)
}

//todo: not in use
export function saveCustomersWithImage(body) {
  let data = new FormData()
  const { files, customer } = body

  if (files && files.length > 0) {
    files.forEach((file, index) => {
      data.append(file.name, file, 'customer')
    })
  }

  if (customer)
    data.append('customer', JSON.stringify(customer))

  return doPost(`${getServerUrl()}${PortalEndpoints.SAVE_CUSTOMER_AND_IMAGE}`, data, { headers: {} })
}

//todo: not in use
export function updateCustomersWithImage(customerId, body) {
  let data = new FormData()
  const { files, customer } = body

  if (files && files.length > 0) {
    files.forEach((file, index) => {
      data.append(file.name, file, 'customer')
    })
  }

  if (customer)
    data.append('customer', JSON.stringify(customer))

  return doPut(`${getServerUrl()}${PortalEndpoints.UPDATE_CUSTOMER}/${customerId}`, data, { headers: {} })
}

export function loadSaleCarByCustomerId(id) {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_SALE_BY_CUSTOMER_ID}${id}/sales`)
}

export function startSale(data) {
  return doPost(`${getServerUrl()}${PortalEndpoints.SALES_API}start`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateLocationStatus(id, status) {
  return doPut(`${getServerUrl()}${PortalEndpoints.UPDATE_LOCATION_STATUS}${id}?status=${status}`, '', { headers: {} })
}

export function loadSalesFileById(saleId, fileTemplateCode) {
  return doGet(`${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}/files/${fileTemplateCode}`)
}

export function getDataFromVinNumber(vinNumber) {
  return doGet((PortalEndpoints.VEHICLES_DATA_URL + PortalEndpoints.VIN_NUMBER_SEARCH_URL).replace('{vinNumber}', vinNumber))
}

export function saveKeywordValues(saleId, data) {
  return doPost(`${getServerUrl() + PortalEndpoints.SALES_API}${saleId}/files`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function saveDealerConfiguration(data) {
  return doPost(`${getServerUrl()}${PortalEndpoints.SAVE_DEALER_CONFIGURATION}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function uploadFileSales(saleId, staticFileCode, body) {
  let data = new FormData()
  const { files } = body

  if (files && files.length > 0) {
    files.forEach((file, index) => {
      let positionPoint = file.name.indexOf('.')
      let ext = file.name.substring(positionPoint + 1, file.name.length)
      let fileName = staticFileCode + '.' + ext
      data.append(file.name, file, file.name)
    })
  }

  return doPost(`${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}/staticFiles/${staticFileCode}`, data, { headers: {} })
}

export function deleteSaleStaticFile(saleId, saleStaticFileId) {
  return doDelete(`${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}/staticFiles/${saleStaticFileId}`)
}

export function loadStaticsFiles(saleId) {
  return doGet(`${getServerUrl() + PortalEndpoints.SALES_API}${saleId}/staticFiles`)
}

export function verifyVin(vin) {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_CAR_BY_ID}?vin=${vin}`)
}

export function loadCheckListBySaleId(saleId) {
  return doGet(`${getServerUrl() + PortalEndpoints.SALES_API}${saleId}/checklist`)
}

export function saveSaleCheck(saleId, data) {
  return doPost(
    `${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}/checklist`,
    JSON.stringify(data),
    {
      headers: { 'Content-Type': 'application/json' }
    }
  )
}

export function loadAllDealers(from, size, keyword) {
  let url = ''

  if (!isEmpty(keyword))
    url = `?keyword=${keyword}`

  if (from) {
    if (isEmpty(url))
      url = `?from=${from}`
    else
      url += `&from=${from}`
  }

  if (size && size !== 0) {
    if (isEmpty(url))
      url = `?size=${size}`
    else
      url += `&size=${size}`
  }
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_ALL_DEALERS}${url}`)
}

export function cancelSale(saleId, reason) {
  return doPost(`${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}/cancel`, JSON.stringify(reason), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateDealersWithImage(dealerId, body) {
  let data = new FormData()
  const { files, dealer } = body

  if (files && files.length > 0) {
    files.forEach((file, index) => {
      data.append(file.name, file, 'dealer')
    })
  }

  if (dealer)
    data.append('dealer', JSON.stringify(dealer))

  return doPut(`${getServerUrl()}${PortalEndpoints.LOAD_ALL_DEALERS}/${dealerId}`, data, { headers: {} })
}

export function updateDealerStatus(dealerId, status) {
  return doPut(`${getServerUrl()}${PortalEndpoints.UPDATE_STATUS_DEALER}${dealerId}?status=${status}`, '', { headers: {} })
}

export function updateSuperuserStatus(userId, status) {
  return doPut(`${getServerUrl()}${PortalEndpoints.UPDATE_STATUS_SUPERUSERS}${userId}/status?status=${status}`, '', { headers: {} })
}

export function updateUserStatus(userId, status) {
  return doPut(`${getServerUrl()}${PortalEndpoints.UPDATE_STATUS_USER}${userId}/status?status=${status}`, '', { headers: {} })
}

export function loadSuperusers(from, size, keyword, showInactives) {
  let url = ''

  if (!isEmpty(keyword))
    url = `?keyword=${keyword}`

  if (from) {
    if (isEmpty(url))
      url = `?from=${from}`
    else
      url += `&from=${from}`
  }

  if (size && size !== 0) {
    if (isEmpty(url))
      url = `?size=${size}`
    else
      url += `&size=${size}`
  }

  if (showInactives !== undefined) {
    if (isEmpty(url))
      url = `?showInactives=${showInactives}`
    else
      url += `&showInactives=${showInactives}`
  }

  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_SUPERUSERS}${url}`)
}

export function loadAllRoles(keyword) {
  let url = ''
  if (!isEmpty(keyword))
    url = `?keyword=${keyword}`
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_ALL_ROLES}${url}`)
}

export function addRegisterSuperuser(superuser, file) {
  let data = new FormData()
  data.append('superuser', JSON.stringify(superuser))

  if (file) {
    data.append(file.name, file)
  }

  return doPost(`${getServerUrl()}${PortalEndpoints.LOAD_SUPERUSERS}`, data, { headers: {} })
}

export function updateSuperuserWithImage(superuserId, body) {
  console.log('updateSuperuserWithImage')
  let data = new FormData()
  const { files, superuser } = body

  if (files && files.length > 0) {
    files.forEach((file, index) => {
      data.append(file.name, file)
    })
  }

  if (superuser)
    data.append('superuser', JSON.stringify(superuser))

  return doPut(`${getServerUrl()}${PortalEndpoints.UPDATE_SUPERUSER}${superuserId}`, data, { headers: {} })
}

export function saveNewDealerAndAccountOwner(body) {
  let data = new FormData()
  const { fileDealer, dealer, fileAccountOwner, accountOwner, fileDealerIcon, orderPayment } = body

  if (fileDealer && fileDealer.length > 0) {
    fileDealer.forEach((file, index) => {
      data.append('dealer', file, file.name)
    })
  }

  if (fileAccountOwner && fileAccountOwner.length > 0) {
    fileAccountOwner.forEach((file, index) => {
      data.append('user', file, file.name)
    })
  }

  if (fileDealerIcon && fileDealerIcon.length > 0) {
    data.append('dealerIcon', fileDealerIcon[0], fileDealerIcon[0].name)
  }

  if (dealer)
    data.append('dealer', JSON.stringify(dealer))

  if (accountOwner)
    data.append('user', JSON.stringify(accountOwner))

  if (orderPayment)
    data.append('orderPayment', JSON.stringify(orderPayment))

  return doPost(`${getServerUrl()}${PortalEndpoints.SAVE_DEALER}`, data, { headers: {} })
}

export function loadDealer() {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_DEALER}`)
}

export function dealerSetup(setupComplete, lastCompletedStep) {
  return doPut(`${getServerUrl()}${PortalEndpoints.DEALER_SETUP}?setupComplete=${setupComplete}&lastCompletedStep=${lastCompletedStep}`, '', { headers: {} })
}

export function getDealerInfo(dealerId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_DEALER}/${dealerId}`)
}

export function updateSetupDealersWithImage(dealerId, body) {
  let data = new FormData()
  const { files, dealer, fileDealerIcon } = body

  if (files && files.length > 0) {
    files.forEach((file, index) => {
      data.append('dealer', file, file.name)
    })
  }

  if (fileDealerIcon && fileDealerIcon.length > 0) {
    data.append('dealerIcon', fileDealerIcon[0], fileDealerIcon[0].name)
  }

  if (dealer)
    data.append('dealer', JSON.stringify(dealer))

  return doPut(`${getServerUrl()}${PortalEndpoints.LOAD_ALL_DEALERS}/${dealerId}/setup`, data, { headers: {} })
}

export function verifySubdomain(subdomain) {
  return doGet(`${getServerUrl()}${PortalEndpoints.VERIFY_SUBDOMAIM}?subdomain=${subdomain}`)
}

export function loadSaleNotes(saleId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}/notes`)
}

export function loadPermissions() {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_PERMISSIONS}`)
}

export function verifyCanDeleteRole(roleId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.DELETE_ROL}/${roleId}/verifyCanDelete`)
}

export function loadSaleConfigurations() {
  return doGet(`${getServerUrl()}${PortalEndpoints.SALES_API}configurations`)
}

export function loadDealerSales(params) {
  let query = { ...params }
  if (query.hasNotPurchaser) {
    delete query['purchaserName']
  }
  if (query.hasNotCar) {
    delete query['makeId']
    delete query['modelId']
    delete query['year']
  }
  const url = toUrlFormEncoded(query)
  return doGet(`${getServerUrl()}api/sales${url}`)
}

export function deleteSale(saleId, password) {
  const params = toUrlFormEncoded({ password })
  return doDelete(`${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}${params}`)
}

export function loadSaleById(saleId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}`)
}

export function saveSaleNote(saleId, data) {
  return doPost(`${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}/notes`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateSaleNote(saleId, noteId, data) {
  return doPut(`${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}/notes/${noteId}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function deleteSaleNote(saleId, noteId) {
  return doDelete(`${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}/notes/${noteId}`, '', { headers: {} })
}

export function loadAllUserActive() {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_ALL_USERS_ACTIVE}`)
}

export function updateSale(saleId, data) {
  let modifiedData = { ...data }

  // Added this because `updateSale` API doesn't validate
  // customers when it's an empty array.
  // And if we go ahead then it will throw an error
  if (isEmpty(modifiedData.customers)) {
    delete modifiedData.customers
  }

  return doPut(
    `${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}`,
    JSON.stringify(modifiedData),
    {
      headers: { 'Content-Type': 'application/json' }
    }
  )
}

export function loadCustomersBySaleId(saleId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}/customers`)
}


export function loadSaleTradeIns(saleId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}/tradeins`)
}

export function saveSaleTradeIn(saleId, data) {
  return doPost(`${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}/tradeins`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateSaleTradeIn(saleId, tradeInId, data) {
  return doPut(`${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}/tradeins/${tradeInId}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function deleteSaleTradeIn(saleId, tradeInId) {
  return doDelete(`${getServerUrl() + PortalEndpoints.SALES_API}${saleId}/tradeins/${tradeInId}`, '', { headers: {} })
}

export function updateCreditScore(saleId, customerId, creditScore) {
  let url = `?customerId=${customerId}`

  if (creditScore) {
    url += `&creditScore=${creditScore}`
  }
  return doPut(`${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}/creditScore${url}`, '', { headers: {} })
}

export function saveFinance(data) {
  return doPost(`${getServerUrl()}${PortalEndpoints.FINANCE_SAVE_UPDATE}finance`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateTaskStatus(id, status, typeTask) {
  return doPut(`${getServerUrl()}${PortalEndpoints.UPDATE_TASK_STATUS}${id}/${status}/${typeTask}`, '', { headers: {} })
}

export function updateReorderTask(data) {
  return doPut(`${getServerUrl()}${PortalEndpoints.UPDATE_REORDER_TASK}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// APIs for Sale Settings
// ----------------------

// Gets all dealer sale static files on a specific dealer
export function loadDealerSaleStaticFiles() {
  return doGet(`${getServerUrl()}${PortalEndpoints.DEALER_SALE_STATIC_FILES}`)
}

// Deletes a specific dealer sale static file based on ID
export function deleteDealerSaleStaticFile(dealerSaleStaticFileId) {
  return doDelete(`${getServerUrl()}${PortalEndpoints.DEALER_SALE_STATIC_FILES}/${dealerSaleStaticFileId}`)
}

// Updates a specific dealer sale static file based on ID
// 'id', 'name' and 'code' properties must be sent. Others are optional
export function updateDealerSaleStaticFile(dealerSaleStaticFileInfo) {
  const optionalString = dealerSaleStaticFileInfo && typeof dealerSaleStaticFileInfo['optional'] === 'boolean'
    ? `&optional=${dealerSaleStaticFileInfo['optional']}`
    : ''
  const orderString = dealerSaleStaticFileInfo && typeof dealerSaleStaticFileInfo['order'] === 'number'
    ? `&order=${dealerSaleStaticFileInfo['order']}`
    : ''

  return doPut(`${getServerUrl()}${PortalEndpoints.DEALER_SALE_STATIC_FILES}/${dealerSaleStaticFileInfo['id']}`
    + `?name=${dealerSaleStaticFileInfo['name']}&code=${dealerSaleStaticFileInfo['code']}${optionalString}${orderString}`)
}

// Creates a new one dealer sale static file
// 'name', 'code', 'optional' and 'order' must be sent
export function createDealerSaleStaticFile(dealerSaleStaticFileInfo) {
  return doPost(`${getServerUrl()}${PortalEndpoints.DEALER_SALE_STATIC_FILES}`, JSON.stringify(dealerSaleStaticFileInfo), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// Updates a list of dealer sale files
// 'id', 'optional' and 'order' are required
export function updateDealerSaleStaticFileList(dealerSaleStaticFileList) {
  return doPut(`${getServerUrl()}${PortalEndpoints.DEALER_SALE_STATIC_FILES_LIST}`, JSON.stringify(dealerSaleStaticFileList), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateReorderChecklist(data) {
  return doPut(`${getServerUrl()}${PortalEndpoints.UPDATE_REORDER_CHECKLIST}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function saveChecklist(data) {
  return doPost(`${getServerUrl()}${PortalEndpoints.SAVE_CHECKLIST}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateChecklist(checklistId, data) {
  return doPut(`${getServerUrl()}${PortalEndpoints.UPDATE_CHECKLIST}${checklistId}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function deleteChecklist(checklistId) {
  return doDelete(`${getServerUrl() + PortalEndpoints.DELETE_CHECKLIST}${checklistId}`, '', { headers: {} })
}

export function loadChecklist() {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_CHECKLIST}`)
}

export function downloadSaleStaticFiles(saleId, code) {
  return (`${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}/staticFiles/${code}/download`)
}

export function loadBalance(saleId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}/balance`)
}

export function saveBalance(saleId, data) {
  return doPost(`${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}/balance`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function loadBalanceSummary(saleId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}/balanceSummary`)
}

export function loadAttributeValuesByAttributeCodes(codes) {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_ATTRIBUTE_VALUES_BY_ATTRIBUTE_CODES}?codes=${codes}`)
}

export function startCompletion(saleId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.SALE_API}${saleId}/startCompletion`)
}

export function endCompletion(saleId, data) {
  return doPut(`${getServerUrl()}${PortalEndpoints.SALE_API}${saleId}/endCompletion`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function endReview(data) {
  return doPut(`${getServerUrl()}${PortalEndpoints.SALE_API}endReview`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateReorderAccessories(data) {
  return doPut(`${getServerUrl()}${PortalEndpoints.UPDATE_REORDER_ACCESSORIES}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function saveAccessory(data) {
  return doPost(`${getServerUrl()}${PortalEndpoints.SAVE_ACCESSORY}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateAccessory(accessoryId, data) {
  return doPut(`${getServerUrl()}${PortalEndpoints.UPDATE_ACCESSORY}${accessoryId}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function deleteAccessory(accessoryId, active) {
  return doPut(`${getServerUrl() + PortalEndpoints.DELETE_ACCESSORY}${accessoryId}/delete?active=${active}`, '', { headers: {} })
}

export function loadAccessories() {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_ACCESSORIES}`)
}

export function getVinByCarId(carId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.GET_VIN}${carId}/vin`)
}

export function addCarTradeIn(saleId, data) {
  return doPost(`${getServerUrl()}${PortalEndpoints.SALE_API}${saleId}/addCarTradeIn`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function getFileTemplateById(fileTemplateId, dealerId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_FILE_TEMPLATE}${fileTemplateId}/loadById?id=${dealerId}`)
}

export function saveFileTemplate(data) {
  return doPost(`${getServerUrl()}${PortalEndpoints.SAVE_FILE_TEMPLATE}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateFileTemplate(fileTemplateId, data) {
  return doPut(`${getServerUrl()}${PortalEndpoints.UPDATE_FILE_TEMPLATE}${fileTemplateId}/update`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateTemporaryCar(carId, temporary) {
  return doPut(`${getServerUrl()}${PortalEndpoints.UPDATE_TEMPORARY_CAR}${carId}/temporary?temporary=${temporary}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function saveCustomersImage(customerId, body) {
  let data = new FormData()
  const { files } = body

  if (files && files.length > 0) {
    files.forEach((file, index) => {
      data.append(file.name, file, 'customer')
    })
  }

  return doPut(`${getServerUrl()}${PortalEndpoints.UPDATE_IMAGE_CUSTOMER}${customerId}/image`, data, { headers: {} })
}

export function loadFinanceByCustomerId(customerId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_CUSTOMER_BY_ID}/${customerId}/finance`)
}

export function findFileTemplateAndForm(fileCode, formCode) {
  return doGet(`${getServerUrl()}${PortalEndpoints.SALES_API}${fileCode}/fileTemplate/${formCode}`)
}

export function deleteDealerConfiguration(configurationId) {
  return doDelete(`${getServerUrl()}${PortalEndpoints.DELETE_DEALER_CONFIGURATION}${configurationId}`, '')
}

export function deleteApplicant(applicantToDelete) {
  return doDelete(`${getServerUrl()}${PortalEndpoints.DELETE_APPLICANT}`, JSON.stringify(applicantToDelete), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function importCSVFile(data, importKey) {
  return doPost(`${getServerUrl()}${getUrlImportByKey(importKey)}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

function getUrlImportByKey(key) {
  switch (key) {
    case importKey.CAR :
      return PortalEndpoints.IMPORT_CSV_FILE_CAR
    case importKey.CUSTOMER :
      return PortalEndpoints.IMPORT_CSV_FILE_CUSTOMER
    default :
      return ''
  }
}
//todo the Component[AccountConfirmation.jsx] that uses this API is not in use.
export function changePasswordAccount(body) {
  return doPost(`${getServerUrl()}${PortalEndpoints.CHANGE_PASSWORD_ACCOUNT}`, JSON.stringify(body), {
    headers: { 'Content-Type': 'application/json' }
  })
}


export function updateCheckFromFinancier(saleId, checkFromFinancier) {
  return doPut(`${getServerUrl()}${PortalEndpoints.SALE_API}${saleId}/updateCheck?checkFromFinancier=${checkFromFinancier}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function getVisualization(reportId, dashboardId, filters = null) {
  const params = !!filters ? toUrlFormEncoded(filters) : ''
  return doGet(`${getServerUrl()}${PortalEndpoints.GET_VISUALIZATION}${reportId}/${dashboardId}${params}`)
}

export function getDealerStateTax() {
  return doGet(`${getServerUrl()}${PortalEndpoints.GET_DEALER_STATE_TAX}`)
}

export function insertOrUpdateDealerStateTax(body) {
  return doPost(`${getServerUrl()}${PortalEndpoints.INSERT_UPDATE_DEALER_STATE_TAX}`, JSON.stringify(body), {
    headers: { 'Content-Type': 'application/json' }
  })
}

export function savePost(body) {
  return doPost(`${getServerUrl()}${PortalEndpoints.SAVE_POST}`, JSON.stringify(body), {
    headers: { 'Content-Type': 'application/json' }
  })
}

export function updatePost(objectId, body) {
  return doPut(`${getServerUrl()}${PortalEndpoints.SAVE_POST}/${objectId}`, JSON.stringify(body), {
    headers: { 'Content-Type': 'application/json' }
  })
}

export function getPostIdByCarId(carId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.GET_POSTID}${carId}/socialNetwork`)
}

export function saveStateTax(saleId, data) {
  return doPost(`${getServerUrl()}${PortalEndpoints.SALE_API}${saleId}/stateTax`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function saveNewApplicant(finance, applicant) {
  const body = {
    finance: finance,
    applicant: applicant
  }
  return doPost(getServerUrl() + PortalEndpoints.SAVE_NEW_FINANCE_APPLICATION, JSON.stringify(body), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function exportSortedCustomers(orderBy) {
  const params = toUrlFormEncoded({ orderBy })
  return `${getServerUrl()}${PortalEndpoints.EXPORT_CUSTOMERS}${params}`
}

export function saveSubscriber(subscriber) {
  return doPost(`${getServerUrl() + MarketingEndpoints.SAVE_SUBSCRIBER}`, JSON.stringify(subscriber), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function unsubscribe(email) {
  return doDelete(`${getServerUrl()}${MarketingEndpoints.UNSUBSCRIBE}/${email}`)
}

export function sendDemoEmail(userForm) {
  return doPost(`${getServerUrl() + MarketingEndpoints.GET_DEMO}`, JSON.stringify(userForm), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function sendContactUsEmail(contactUsForm) {
  return doPost(`${getServerUrl() + MarketingEndpoints.SEND_CONTACT_INFO_EMAIL}`, JSON.stringify(contactUsForm), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function resetFormAttributes(id) {
  return doPut(`${getServerUrl()}${PortalEndpoints.FORM_API}${id}/formAttributes`, '')
}

export function saveSocialNetworkLoginHistory(data) {
  return doPost(`${getServerUrl()}${PortalEndpoints.SOCIAL_NETWORK_LOGIN_HISTORY_API}save`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateSalesmanId(saleId, data) {
  return doPut(`${getServerUrl()}${PortalEndpoints.SALES_API}${saleId}/salesman`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateFinanceStatus(financeId, status) {
  return doPut(`${getServerUrl()}${PortalEndpoints.LOAD_FINANCES}/${financeId}/status?status=${status}`, '', { headers: {} })
}
//todo: not in use
export function generateCSVUrlForFBMP() {
  return doGet(`${getServerUrl()}${PortalEndpoints.GENERATE_CSV_URL_FOR_FBMP}`)
}

/*
  Authorize.net endpoints
 */

export function apiGenerateInvoice(invoiceId) {
  return `${getServerUrl()}${PortalEndpoints.INVOICE_BILLING_API}${invoiceId}`
}

export function testTransaction(data) {
  return doPost(`${getServerUrl()}${PortalEndpoints.CHARGE_TRANSACTION}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function savePaymentMethodOld(data) {
  return doPost(`${getServerUrl()}${PortalEndpoints.ADD_PAYMENT_METHOD}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updatePaymentMethod(data) {
  return doPut(`${getServerUrl()}${PortalEndpoints.UPDATE_PAYMENT_METHOD}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function deletePaymentMethodOld(customerPaymentProfileId) {
  return doDelete(`${getServerUrl()}${PortalEndpoints.DELETE_PAYMENT_METHOD}/${customerPaymentProfileId}`)
}

export function getAllPaymentMethodsDealer() {
  return doGet(`${getServerUrl()}${PortalEndpoints.GET_PAYMENT_METHODS}`)
}

// todo use to payment methods customers
/*export function getPaymentMethodsByCustomerProfile(customerProfileId) {
  return doGet(`${getServerUrl()}${PortalEndpoints.GET_PAYMENT_METHODS}/${customerProfileId}`);
}*/

// deprecated
export function getAcceptSuiteScript(formType) {
  return doGet(`${getServerUrl()}${PortalEndpoints.GET_ACCEPT_SUIT}/${formType}`)
}

//Braintree Apis

export function clientToken() {
  return doGet(`${getServerUrl()}${PortalEndpoints.CLIENT_TOKEN}`)
}

export function processOrder(orderId, nonce, amount, deviceData, paymentType, currentDue, token = null, storeInVaultOnSuccess = false) {
  const data = { orderId, nonce, amount, deviceData, paymentType, currentDue, token, storeInVaultOnSuccess }
  return doPost(`${getServerUrl()}${PortalEndpoints.PROCESS_ORDER}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function initializePaymentProcessor() {
  return doGet(`${getServerUrl()}${PortalEndpoints.INITIALIZE_PAYMENT_PROCESSOR}`)
}

export function loadSEOByMetadataTypes(metadataType) {
  const params = toUrlFormEncoded({ metadataType })
  return doGet(`${getServerUrl()}${PortalEndpoints.SEO_API}/metadataType${params}`)
}

//Credential APIs
export function loadCredentialByType(type) {
  const params = toUrlFormEncoded({ type })
  return doGet(`${getServerUrl()}${PortalEndpoints.CREDENTIAL_API}${params}`)
}

export function saveCredentials(data) {
  return doPut(`${getServerUrl()}${PortalEndpoints.CREDENTIAL_API}s`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function loadCredentialsByTypes(types) {
  const params = toUrlFormEncoded({ types })
  return doGet(`${getServerUrl()}${PortalEndpoints.CREDENTIAL_API}s${params}`)
}

//Testimonials APIs
export function updateTestimonial(getAdditionalInfo = false) {
  return doPost(`${getServerUrl()}${PortalEndpoints.UPDATE_TESTIMONIALS}/${getAdditionalInfo}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function loadTestimonials(from, size, keyword, getAdditionalInfo, socialNetwork) {
  const params = toUrlFormEncoded({ from, size, keyword, getAdditionalInfo, socialNetwork })
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_TESTIMONIALS}${params}`)
}

export function replyTestimonial(reviewId, data) {
  return doPost(`${getServerUrl() + PortalEndpoints.REPLY_TESTIMONIAL}${reviewId}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function editTestimonialComment(commentId, data) {
  return doPost(`${getServerUrl() + PortalEndpoints.EDIT_TESTIMONIAL_COMMENT}${commentId}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function verifyWebhookSubscription() {
  return doGet(`${getServerUrl()}${PortalEndpoints.VERIFY_PAGE_SUBSCRIPTION}`)
}


export function subscribePageToWebhook() {
  return doPost(`${getServerUrl() + PortalEndpoints.SUBSCRIBE_PAGE_TO_WEBHOOK}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

//Feature Flags
export function loadFeatureFlags() {
  return doGet(`${getServerUrl() + PortalEndpoints.FEATURE_FLAGS}`)
}

// Used within a SuperUser account
export function updateFeatureFlag(flagName, active, dealerId) {
  const params = toUrlFormEncoded({ flagName, active, dealerId })
  return doPut(getServerUrl() + PortalEndpoints.FEATURE_FLAGS + params)
}

// Used within a SuperUser account
export function saveFeatureFlag(data) {
  return doPost(`${getServerUrl()}${PortalEndpoints.FEATURE_FLAGS}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// Used within a SuperUser account
export function deleteFeatureFlagApi(flagName, dealerId) {
  return doDelete(`${getServerUrl() + PortalEndpoints.FEATURE_FLAGS}/${flagName}/${dealerId}`, '', { headers: {} })
}

export function loadActiveFeatureFlagsApi() {
  return doGet(`${getServerUrl()}${PortalEndpoints.FEATURE_FLAGS}/actives`)
}

export function getQuantityGroupByFlagApi() {
  return doGet(`${getServerUrl()}${PortalEndpoints.FEATURE_FLAGS}/getQuantityGroupByFlag`)
}

export function getByFlagNameApi(flagName) {
  const params = toUrlFormEncoded({ flagName })
  return doGet(`${getServerUrl()}${PortalEndpoints.FEATURE_FLAGS}/getByFlagName${params}`)
}

//Webhook Events
export function searchWebhookEvents(pageNum = 0, pageSize = 50) {
  const params = toUrlFormEncoded({ pageNum, pageSize })
  return doGet(`${getServerUrl() + PortalEndpoints.WEBHOOK_DATA + params}`)
}

export function loadOrderByStatus(status) {
  const params = toUrlFormEncoded({ status })
  return doGet(`${getServerUrl()}${PortalEndpoints.ORDER_API}status${params}`)
}

export function loadLastOrder(dealerId) {
  const params = toUrlFormEncoded({ dealerId })
  return doGet(`${getServerUrl()}${PortalEndpoints.ORDER_API}loadLastOrder${params}`)
}

export function loadLastPaidOrder(){
  return doGet(`${getServerUrl()}${PortalEndpoints.ORDER_API}loadLastPaidOrder`)
}

export function loadSubscriptions(){
  return doGet(`${getServerUrl()}${PortalEndpoints.INVENTORY_ITEM_API}/subscriptions`)
}

export function createSubscription(data) {
  return doPost(`${getServerUrl()}${PortalEndpoints.SAVE_DEALER}/createSubscription`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function updateSubscription(data) {
  return doPost(`${getServerUrl()}${PortalEndpoints.SAVE_DEALER}/updateSubscription`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
export function loadPackages() {
  return doGet(`${getServerUrl()}${PortalEndpoints.INVENTORY_ITEM_API}/packages`)
}

export function savePackage(data) {
  return doPost(`${getServerUrl()}${PortalEndpoints.INVENTORY_ITEM_API}`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function loadInvoices(dealerId) {
  const params = toUrlFormEncoded({ dealerId })
  return doGet(`${getServerUrl()}${PortalEndpoints.LOAD_INVOICES}${params}`)
}

export function createOrderFromDealer(data) {
  return doPost(`${getServerUrl()}${PortalEndpoints.CREATE_INVOICE}`, JSON.stringify(data),
    { headers: { 'Content-Type': 'application/json' } }
  )
}

//todo: data :{nonce: String, makeDefault: Option[Boolean]}
export function savePaymentMethod(data) {
  return doPost(`${getServerUrl()}${PortalEndpoints.PAYMENT_API}method/save`, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function deletePaymentMethod(token) {
  return doDelete(`${getServerUrl()}${PortalEndpoints.PAYMENT_API}method/${token}`)
}

export function validateAccess() {
  return doGet(`${getServerUrl()}${PortalEndpoints.VALIDATE_ACCESS}`)
}

//Google -> Sign in
export function sendAuthorizationCode(authorizationCode) {
  return doPost(getServerUrl() + PortalEndpoints.GOOGLE_AUTHORIZATION, JSON.stringify(authorizationCode), {
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest'
    }
  })
}

export function getAccountData() {
  return doGet(`${getServerUrl()}${PortalEndpoints.GOOGLE_API}accountData`)
}

export function updateCredentialLocationId(locationId) {
  const params = toUrlFormEncoded({locationId});
  return doPut(`${getServerUrl()}${PortalEndpoints.GOOGLE_API}updateCredential${params}`, "", {headers: {}})
}

export function replyGoogleReview(data){
  return doPut(`${getServerUrl()}${PortalEndpoints.GOOGLE_API}replyReview`,JSON.stringify(data),{headers: {
    'Content-Type': 'application/json'
  }})
}

export function updateGoogleTestimonials() {
  return doPost(`${getServerUrl()}${PortalEndpoints.UPDATE_GOOGLE_TESTIMONIALS}`, {
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

//todo: deprecated: not used
export function getGoogleLocationInfo() {
  return doGet(`${getServerUrl()}${PortalEndpoints.GOOGLE_API}getLocationInfo`)
}

export function loadReviewConfigurations(dealerId) {
  const params = toUrlFormEncoded({ dealerId })
  return doGet(`${getServerUrl()}${PortalEndpoints.REVIEW_API}configurations${params}`)
}

export function updateKeepLoggedIn(keepLoggingChecked) {
  const body = JSON.stringify({
    keepLoggedIn: keepLoggingChecked,
  });

  return doPost(getServerUrl() + PortalEndpoints.UPDATE_KEEP_LOGGED_IN, body, { headers : {
    'Content-Type': 'application/json'
  }})
}